<app-nav></app-nav>
<div class="container1">
  <div class="h1-container">
    <table>
      <th>
        <tr>
          <h2
            class="backlink"
            style="margin: -5px 0 1px; text-align: left; color: #3f51b5"
            (click)="logOut()"
          >
            {{
              'text.academic.performance.monitoring.start.page'
                | translate: '< На стартовую'
            }}
          </h2>
        </tr>
        <tr>
          <div style="margin-top: 20px; margin-bottom: 10px">
            <span class="h1">
              {{
                'text.academic.performance.monitoring'
                  | translate: 'Статистика успеваемости'
              }}</span
            >
            <!--div style="display:inline-block; padding-top: 0px;"><button mat-icon-button #helpButton class="mat-icon-button-help" matTooltip="Помощь" matTooltipPosition="right" (click)="showHelp()">
              <mat-icon>help_outline</mat-icon>
          </button></div-->
          </div>
        </tr>
      </th>
    </table>
  </div>
  <p></p>
  <div class="container2">
    <div
      style="
        line-height: 1.8;
        font-size: 14px;
        color: #000000;
        font-family:
          Roboto,
          Helvetica Neue,
          sans-serif;
      "
    >
      <p>
        {{
          'text.academic.performance.monitoring.start.info.first'
            | translate
              : 'Для просмотра успеваемости студентов введите номер их группы. Если интересует успеваемость отдельного студента, то добавьте его фамилию.'
        }}
      </p>
      <p>
        {{
          'text.academic.performance.monitoring.start.info.forth'
            | translate
              : 'Если интересует статистика успеваемости за определенный период, выберите соответствующие даты. '
        }}
      </p>
      <p>
        {{
          'text.academic.performance.monitoring.start.info.second'
            | translate
              : 'Можно просмотреть суммарную статистику успеваемости по всем изучаемым в настоящее время предметам, а также выборочно по каждому предмету. Также можно ознакомиться со статистикой успеваемости по изученным ранее предметам.'
        }}
      </p>
      <p>
        {{
          'text.academic.performance.monitoring.start.info.third'
            | translate
              : 'Полученную информацию можно распечатать либо экспортировать в MS Excel.'
        }}
      </p>
    </div>

    <div class="form">
      <form [formGroup]="controlForm">
        <table style="width: 100%">
          <tr>
            <td>
              <div class="form-first-row">
                <mat-form-field
                  class="default"
                  color="primary"
                  style="margin-left: 5px; width: 100%; float: left"
                >
                  <input
                    matInput
                    color="primary"
                    formControlName="groupName"
                    placeholder="{{
                      'text.academic.performance.monitoring.enter.group'
                        | translate: 'Введите номер группы'
                    }}"
                    type="text"
                  />
                </mat-form-field>
                <mat-form-field
                  class="default"
                  color="primary"
                  style="margin-left: 5px; width: 100%; float: right"
                >
                  <input
                    matInput
                    color="primary"
                    formControlName="surname"
                    placeholder="{{
                      'text.academic.performance.monitoring.enter.surname'
                        | translate: 'Введите фамилию студента'
                    }}"
                    type="text"
                  />
                </mat-form-field>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-first-row">
                <mat-form-field
                  class="default"
                  color="primary"
                  style="margin-left: 5px; width: 100%; float: left"
                >
                  <input
                    disabled
                    matInput
                    [placeholder]="
                      'text.academic.performance.monitoring.period.begin'
                        | translate: 'Начало периода'
                    "
                    [matDatepicker]="picker"
                    formControlName="start"
                  />
                  <mat-datepicker-toggle
                    [disabled]="false"
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker [disabled]="false" #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field
                  class="default"
                  color="primary"
                  style="margin-left: 5px; width: 100%; float: right"
                >
                  <input
                    disabled
                    matInput
                    [placeholder]="
                      'text.academic.performance.monitoring.period.end'
                        | translate: 'Окончание периода'
                    "
                    [matDatepicker]="picker1"
                    formControlName="end"
                  />
                  <mat-datepicker-toggle
                    [disabled]="false"
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker [disabled]="false" #picker1></mat-datepicker>
                </mat-form-field>
              </div>
            </td>
          </tr>
        </table>
        <a
          class="button-search"
          mat-flat-button
          color="primary"
          (click)="enterGroup()"
          >{{ 'search' | translate: 'Найти' }}</a
        >
        <!--a mat-flat-button color="primary" href="/login">Выйти</a-->
      </form>
    </div>
  </div>
</div>
<iframe *ngIf="isLoad" [src]="selectedGroup"></iframe>
