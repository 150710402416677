<mat-spinner
  mode="indeterminate"
  *ngIf="!profileInfo"
  class="spinner"
  color="primary"
></mat-spinner>
<div *ngIf="this.profileInfo">
  <div class="header-row">
    <h2 class="header">{{ this.profileInfo.Name }}</h2>
  </div>

  <div class="profile">
    <mat-card class="profile-card" style="padding-bottom: 0px">
      <mat-card class="avatar">
        <img
          class="avatarImg"
          *ngIf="this.profileInfo.Avatar"
          src="{{ this.profileInfo.Avatar }}"
        />
        <span
          *ngIf="!this.profileInfo.Avatar"
          class="avatar-title rounded-circle bg-soft-primary text-primary"
        >
          {{ this.Surname.charAt(0) + this.Name.charAt(0) }}
        </span>
      </mat-card>

      <mat-card-content class="under-avatar">
        <p *ngIf="this.profileInfo.Group">
          {{ 'text.profile.group' | translate: 'Группа' }}:
          {{ this.profileInfo.Group }}
        </p>
        <p *ngIf="!this.isStudent() && this.profileInfo.Skill">
          {{ 'text.profile.skill' | translate: 'Должность' }}:
          {{ this.profileInfo.Skill }}
        </p>
        <p *ngIf="this.isStudent() && this.profileInfo.LastLogitData">
          {{ 'text.profile.lastLogin' | translate: 'Последний визит' }}:
          {{ this.profileInfo.LastLogitData }}
        </p>
        <p *ngIf="this.profileInfo.SkypeContact">
          {{
            'text.personalAccount.messenger'
              | translate: 'Skype, Viber, Telegram и др.'
          }}: {{ this.profileInfo.SkypeContact }}
        </p>
        <p *ngIf="this.profileInfo.Email">
          {{ 'text.personalAccount.email' | translate: 'Почта' }}:
          {{ this.profileInfo.Email }}
        </p>
        <p *ngIf="this.profileInfo.Phone">
          {{ 'text.personalAccount.phoneNumber' | translate: 'Телефон' }}:
          {{ this.profileInfo.Phone }}
        </p>
        <p *ngIf="this.profileInfo.About">
          {{ 'text.personalAccount.about' | translate: 'О себе' }}:
          {{ this.profileInfo.About }}
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="data-container">
      <div class="project-table">
        <table mat-table [dataSource]="this.currentSubjects">
          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'text.profile.currentSubjects'
                  | translate: 'Текущие предметы пользователя'
              }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
          </ng-container>

          <ng-container matColumnDef="complete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ element.Completing }}%
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsSecond"
          ></tr>
        </table>
      </div>
      <div class="project-table">
        <table mat-table [dataSource]="this.archivedSubjects">
          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'text.profile.complitedSubjects'
                  | translate: 'Завершенные предметы пользователя'
              }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
          </ng-container>

          <ng-container matColumnDef="complete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">100%</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsSecond"
          ></tr>
        </table>
      </div>
    </mat-card>

    <mat-card class="data-container">
      <div class="project-table">
        <table mat-table [dataSource]="this.cpProfileProjects">
          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'text.profile.cp' | translate: 'Текущие курсовые проекты' }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.Name }} ({{ element.SubjectName }})
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="project-table">
        <table mat-table [dataSource]="this.dpProfileProjects">
          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef>{{ getDpString() }}</th>
            <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-card>
  </div>
</div>
