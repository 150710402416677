import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class AppToastrService {
  constructor(private toastr: ToastrService) {}

  addSuccessFlashMessage(msg: string) {
    this.toastr.success(msg)
  }

  addErrorFlashMessage(msg: string) {
    this.toastr.error(msg)
  }

  addWarningFlashMessage(msg: string) {
    this.toastr.warning(msg)
  }
}
