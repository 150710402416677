<div
  style="position: absolute; z-index: 100000"
  *ngIf="IsActiveCall || IsIncomingCall"
>
  <div
    ngDraggable
    [ngResizable]="IsActiveCall"
    [rzHandles]="'se,sw,ne,nw'"
    [rzAspectRatio]="1"
    [rzMinWidth]="250"
    zIndex="'100000'"
  >
    <div *ngIf="IsActiveCall" class="video-call">
      <div *ngIf="!IsCallAccepted" class="video-call__audio">
        <audio
          id="outgoingCallSound"
          src="/assets/audios/outgoing_call_sound.mp3"
          autoplay
          loop
        ></audio>
      </div>
      <div class="video-call__videos">
        <app-stream-handler
          #child
          (clientDisconnected)="this.clientDisconnected()"
          (clientConnected)="this.stopCallSound('outgoingCallSound')"
          [isMicroActive]="this.IsMicroActive"
          [isVideoActive]="this.isVideoActive"
        ></app-stream-handler>
      </div>

      <div class="video-call__controls">
        <div class="video-call__control" (click)="this.switchMicro()">
          <mat-icon
            class="control-icon"
            *ngIf="IsMicroActive"
            [ngStyle]="{ color: 'white' }"
            >mic</mat-icon
          >
          <mat-icon
            class="control-icon"
            *ngIf="!IsMicroActive"
            [ngStyle]="{ color: 'white' }"
            >mic_off</mat-icon
          >
        </div>
        <div class="video-call__control" (click)="this.switchVideo()">
          <mat-icon
            class="control-icon"
            *ngIf="isVideoActive"
            [ngStyle]="{ color: 'white' }"
            >videocam</mat-icon
          >
          <mat-icon
            class="control-icon"
            *ngIf="!isVideoActive"
            [ngStyle]="{ color: 'white' }"
            >videocam_off</mat-icon
          >
        </div>

        <mat-icon
          class="control-icon"
          (click)="endCall()"
          [ngStyle]="{ color: 'red' }"
        >
          call_end</mat-icon
        >
      </div>
    </div>
    <div *ngIf="IsIncomingCall">
      <audio
        id="incomingCallSound"
        src="/assets/audios/incoming_call_sound.mp3"
        autoplay
        loop
      ></audio>
      <div class="caller-information" *ngIf="this.videoChatService.chat">
        <img
          style="height: 36px !important"
          *ngIf="this.videoChatService.chat?.img"
          src="{{ this.videoChatService.chat.img }}"
          class="rounded-circle avatar-xs"
          alt=""
        />
        <div
          class="caller-information__name"
          *ngIf="this.videoChatService?.chat"
        >
          {{ this.videoChatService.chat?.name }}
        </div>
      </div>
      <div class="video-call__controls">
        <mat-icon
          class="control-icon"
          (click)="answerCall()"
          [ngStyle]="{ color: 'green' }"
        >
          call</mat-icon
        >
        <mat-icon
          class="control-icon"
          (click)="endCall()"
          [ngStyle]="{ color: 'red' }"
        >
          call_end</mat-icon
        >
      </div>
    </div>
  </div>
</div>
