<div class="page-wrap">
  <h1>User manual for lecturers</h1>
  <div class="container">
    <div class="manual-column">
      <h2>Schedule</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add an event to the schedule?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Go to the schedule page by clicking the
              &laquo;<strong>CATS</strong>&raquo; logo in the upper left corner
              of the screen.
            </li>
            <li>Double-click a cell that meets the time you need.</li>
            <li>
              You will see a pop-up window. Select the required event type
              there. It can be either a lesson or an event.
            </li>
            <li>Enter the necessary data.</li>
            <li>Press the <strong>[Save]</strong> button.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit an event?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Find the event you want to edit and press the [...] button.</li>
            <li>
              There will be <strong>Edit</strong> and
              <strong>Delete</strong> options.
            </li>
            <li>Press the <strong>[Edit]</strong> button.</li>
            <li>You will see a pop-up window. Edit the data you need there.</li>
            <li>Press the <strong>[Save]</strong> button.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to delete an event?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Find the event you want to delete and press [...] button.</li>
            <li>
              There will be <strong>Edit</strong> and
              <strong>Delete</strong> options.
            </li>
            <li>Press the <strong>[Delete]</strong> button.</li>
            <li>
              You will see a pop-up window. Click <strong>[Yes]</strong> to
              confirm deletion or <strong>[No]</strong> to cancel it.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Courses</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to add a course?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Courses]</strong> button in the top menu.
            </li>
            <li>
              Press the <strong>[Course management]</strong> button on the
              following page.
            </li>
            <li>Then click the <strong>[Add course]</strong> button.</li>
            <li>
              Fill in the data. Please note that the <strong>News</strong> unit
              is included automatic, the other ones are optional. You are able
              to edit this information whenever you want.
            </li>
            <li>Press the <strong>[Save]</strong> button.</li>
            <li>The added course will appear in the courses list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit a course?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Courses]</strong> button in the top menu.
            </li>
            <li>
              Press the <strong>[Course management]</strong> button on the
              following page.
            </li>
            <li>Then click the <strong>[Edit course]</strong> icon.</li>
            <li>
              Edit the data you need and press the
              <strong>[Save]</strong> button.
            </li>
            <li>The edited course will appear in the courses list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to delete a course?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Courses]</strong> button in the top menu.
            </li>
            <li>
              Press the <strong>[Course management]</strong> button on the
              following page.
            </li>
            <li>Then click the <strong>[Delete course]</strong> icon.</li>
            <li>
              You will see a dialog window. If you are sure you want to delete
              the course, press the <strong>[Delete]</strong> button, otherwise
              press <strong>[Cancel]</strong>.
            </li>
            <li>
              The course will be deleted and you will no longer see it in the
              courses list.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to join a lector?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Courses]</strong> button in the top menu.
            </li>
            <li>
              Press the <strong>[Course management]</strong> button on the
              following page
            </li>
            <li>
              Find the required course in the list and click the
              <strong>[Join lector]</strong>
              icon.
            </li>
            <li>
              There will be a dialog window. Select the lector you need and
              press the <strong>[Join]</strong> button.
            </li>
            <li>The lector you chose is now joined and can manage it.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to enter a course page?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Press the <strong>[Courses]</strong> button in the top menu.
            </li>
            <li>
              Click the <strong>[Choose course]</strong> label on the following
              page.
            </li>
            <li>
              There will be a drop-down menu. Select the course you need there.
            </li>
            <li>You will be redirected to the page of the required course.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>News</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to add news?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>News</strong> section.</li>
            <li>Press the <strong>[Add news]</strong> button.</li>
            <li>
              There will be a pop-up window, where you are to input data. Then
              press the <strong>[Save]</strong> button.
            </li>
            <li>The news you added will be shown in the list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit news?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>News</strong> section.</li>
            <li>
              Hover the mouse over required news. Click the
              <strong>[Edit]</strong> icon.
            </li>
            <li>
              There will be a pop-up window, where you are to edit data. Then
              press the <strong>[Save]</strong> button.
            </li>
            <li>The changes you made will be shown in the news list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to delete news?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>News</strong> section.</li>
            <li>
              Hover the mouse over required news. Click the
              <strong>[Delete]</strong> icon.
            </li>
            <li>
              There will be a pop-up window. If you are sure you want to delete
              the news, press the <strong>[Delete]</strong> button, otherwise
              press <strong>[Cancel]</strong>.
            </li>
            <li>
              The news will be deleted and you will no longer see in the news
              list.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to hide news on the homepage?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>News</strong> section.</li>
            <li>Press the <strong>[Hide all news]</strong> button.</li>
            <li>
              All the news will be hidden and will not be shown on the homepage.
              Hidden news will be displayed on the left of the news section. The
              color of the news will be switched to gray.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to show news on the homepage?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>News</strong> section.</li>
            <li>Press the <strong>[Show all news]</strong> button.</li>
            <li>
              All the news will be no longer hidden and will be shown on the
              homepage. Every news will have its own color you assigned to it.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Lectures</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to add a lecture?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Lectures</strong> section.</li>
            <li>Press the <strong>[Add lecture]</strong> button.</li>
            <li>
              Input data in a pop-up window. Press the
              <strong>[Save]</strong> button.
            </li>
            <li>The lecture will be shown in the lectures list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add or delete lecture's date?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Lectures</strong> section.</li>
            <li>Then go to the <strong>Attending lectures</strong> tab.</li>
            <li>Press the <strong>[Schedule management]</strong> button.</li>
            <li>
              Enter the required data in a pop-up window and press the
              <strong>[Save]</strong>
              button.
            </li>
            <li>
              The date you added will be shown in the list below. If you want to
              delete it, press the <strong>[Delete]</strong> icon in this list.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to delete dates of all the lectures?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Lectures</strong> section.</li>
            <li>Then go to the <strong>Attending lectures</strong> tab.</li>
            <li>Press the <strong>[Delete all dates]</strong> button.</li>
            <li>
              You will see a pop-up window. Press <strong>[Delete]</strong> to
              confirm deletion. Otherwise press <strong>[Cancel]</strong>.
            </li>
            <li>The dates of all lectures will be deleted.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to mark lectures attendance?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Lectures</strong> section.</li>
            <li>Then go to the <strong>Attending lectures</strong> tab.</li>
            <li>Double-click any cell that matches the required date.</li>
            <li>
              You are able to mark every absent student in a pop-up window by
              entering the required information. When you finish press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Workshops</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> How to add a lesson? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Workshops</strong> section.</li>
            <li>Press the <strong>[Add lesson]</strong> button.</li>
            <li>
              Input data in a pop-up window. Press the
              <strong>[Save]</strong> button.
            </li>
            <li>The lecture will be shown in the lessons list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add or delete lesson's date?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Workshops</strong> section.</li>
            <li>Then go to the <strong>Submission schedule</strong> tab.</li>
            <li>Press the <strong>[Date management]</strong> button.</li>
            <li>
              Enter the required data in a pop-up window and press the
              <strong>[Add]</strong> button.
            </li>
            <li>
              The date you added will be shown in the list below. If you want to
              delete it, press the <strong>[Delete]</strong> icon in this list.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to mark lesson attendance?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Workshops</strong> section.</li>
            <li>Then go to the <strong>Lesson attendance</strong> tab.</li>
            <li>Double-click any cell that matches the required date.</li>
            <li>
              You are able to mark every absent student in a pop-up window by
              entering the required information. When you finish press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to mark works?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Workshops</strong> section.</li>
            <li>Then go to the <strong>Results</strong> tab.</li>
            <li>Double-click any cell that matches the required date.</li>
            <li>
              You are able to mark every student’s work in a pop-up window by
              entering the required information. When you finish press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to divide a group into subgroups?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Workshops</strong> section.</li>
            <li>
              Then go to the <strong>Submission schedule</strong>,
              <strong>Lesson attendance</strong> or
              <strong>Results</strong> tab.
            </li>
            <li>Click the <strong>[Subdivision]</strong> icon.</li>
            <li>
              Press the <strong>[Save]</strong> button to confirm changes.
              Otherwise press <strong>[Cancel]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Laboratory works</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to add a laboratory work?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Laboratory works</strong> section.</li>
            <li>Press the <strong>[Add work]</strong> button.</li>
            <li>
              Input data in a pop-up window. Press the
              <strong>[Save]</strong> button.
            </li>
            <li>The work will be shown in the works list.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add or delete laboratory work's date?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Laboratory works</strong> section.</li>
            <li>Then go to the <strong>Submission schedule</strong> tab.</li>
            <li>Press the <strong>[Date management]</strong> button.</li>
            <li>
              Enter the required data in a pop-up window and press the
              <strong>[Add]</strong>
              button.
            </li>
            <li>
              The date you added will be shown in the list below. If you want to
              delete it, press the <strong>[Delete]</strong> icon in this list.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to mark laboratory class attendance?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Laboratory works</strong> section.</li>
            <li>Then go to the <strong>Lesson attendance</strong> tab.</li>
            <li>Double-click any cell that matches the required date.</li>
            <li>
              You are able to mark every absent student in a pop-up window by
              entering the required information. When you finish press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to check and mark students’ laboratory works?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Laboratory works</strong> section.</li>
            <li>Then ho to the <strong>Works submission</strong> tab.</li>
            <li>
              Look for the student you need and click. You will see a list of
              the works they sent.
            </li>
            <li>
              Look for the work you need and click its’ attached file title to
              download it.
            </li>
            <li>
              If the work is fine, press the <strong>[Accept]</strong> button.
              Otherwise press the <strong>[Send back]</strong> button. In this
              case you will see a pop-up window where you are to input necessary
              data and press the <strong>[Send work]</strong> button.
            </li>
            <li>
              If you accepted a work by mistake press the
              <strong>[Reject work]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to divide a group into subgroups?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Laboratory works</strong> section.</li>
            <li>
              Then go to the <strong>Submission schedule</strong>,
              <strong>Lesson attendance</strong> or
              <strong>Results</strong> tab.
            </li>
            <li>Click the <strong>[Subdivision]</strong> icon.</li>
            <li>
              You will see a pop-up window where you are able to move students
              from one subgroup to another.
            </li>
            <li>
              Press the <strong>[Save]</strong> button to confirm changes.
              Otherwise press <strong>[Cancel]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Files</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download all the course files?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Files</strong> section.</li>
            <li>Click the <strong>[Download as archive]</strong> icon.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download a course file specifically?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Files</strong> section.</li>
            <li>Select the file you need by clicking it and download it.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Educational complex</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to watch an educational complex?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Educational complex</strong> section.</li>
            <li>Select the required educational complex by clicking it.</li>
            <li>
              The educational complex you need will be shown in a new window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to add a new educational complex?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter a course page.</li>
            <li>Go to the <strong>Educational complex</strong> section.</li>
            <li>Press the <strong>[+]</strong> button.</li>
            <li>
              You will see a pop-up window where you are to input everything you
              need. Press <strong>[Save]</strong> when you finish.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Interactive tutorial</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to add a new tutorial?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Press the <strong>[+]</strong> button.</li>
            <li>
              Input the tutorial title in the pop-up window and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to rename a tutorial?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Find the tutorial you need and right-click it.</li>
            <li>
              Press the <strong>[Rename]</strong> button in the drop-down menu.
            </li>
            <li>
              Input the new name in the pop-up window and press the
              <strong>[Save]</strong>
              button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to delete a tutorial?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Find the tutorial you need and right-click it.</li>
            <li>
              Press the <strong>[Delete]</strong> button in the drop-down menu.
            </li>
            <li>Confirm or deny your actions in the pop-up window.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> How to add a new topic? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Find the tutorial you need and right-click it.</li>
            <li>
              Press the <strong>[Add new theme]</strong> button in the drop-down
              menu.
            </li>
            <li>
              Input the topic name in the pop-up window and press the
              <strong>[Save]</strong>
              button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to close or open access to a tutorial?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Find the tutorial you need and right-click it.</li>
            <li>
              Press the <strong>[Close]</strong> or
              <strong>[Open]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit a tutorial's text?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Interactive tutorial</strong> section.</li>
            <li>Find the tutorial you need and right-click it.</li>
            <li>
              You will be able to edit the text of the tutorial. Place
              everything you need there.
            </li>
            <li>
              Press the <strong>[Save]</strong> button to confirm changes.
              Otherwise press <strong>[Cancel]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="manual-column">
      <h2>Settings</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to confirm or block access to Educats for a student?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              In the top menu there is a <strong>[New students]</strong> icon.
              Click it.
            </li>
            <li>
              You will be redirected to a new page. You are able to find the
              student you need by entering their group or name.
            </li>
            <li>
              Look for the student you need and click the
              <strong>[Confirm access]</strong> or
              <strong>[Block access]</strong> icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit personal account data?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Personal account]</strong> icon in the top
              menu.
            </li>
            <li>
              You will be redirected to a new page. You are able to upload an
              image or edit information about yourself there.
            </li>
            <li>
              Press the <strong>[Save changes]</strong> button if you want to
              confirm changes. Otherwise press <strong>[Close]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to change password?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>[Personal account]</strong> icon in the top
              menu.
            </li>
            <li>
              Look for the <strong>[Change password]</strong> button on the next
              page and press it.
            </li>
            <li>
              You will see a dialog window where you are to enter the new
              password. Press the <strong>[Change]</strong> button if you want
              to confirm changes. Otherwise press <strong>[Cancel]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Tests</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to add a test?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>Press the <strong>[Add test]</strong> button.</li>
            <li>
              In the pop-up page fill in the fields and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit a test?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Edit test]</strong> icon.
            </li>
            <li>
              In the pop-up page edit the required fields and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to delete a test?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Delete test]</strong> icon.
            </li>
            <li>In the pop-up page press the <strong>[Yes]</strong> button.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to add questions to the test?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Go to questions]</strong> icon.
            </li>
            <li>
              In the pop-up page press the
              <strong>[Add question]</strong> button (to add a new question) or
              press the
              <strong>[Add a question from another test]</strong> button (to add
              a question from an existing test).
            </li>
            <li>
              Fill in the fields or select a question from an existing test and
              press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit a question in a test?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Go to questions]</strong> icon.
            </li>
            <li>
              In the pop-up page find the necessary question and click the
              <strong>[Edit question]</strong> icon.
            </li>
            <li>
              Edit the required fields and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to delete a question in a test?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Go to questions]</strong> icon.
            </li>
            <li>
              In the pop-up page find the necessary question and click the
              <strong>[Delete question]</strong> icon.
            </li>
            <li>
              In the pop-up window press the <strong>[Yes]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to open a test for students to pass?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Test availability]</strong> icon.
            </li>
            <li>
              In the pop-up page select group, press the
              <strong>[Open for everyone]</strong> button (to open the test for
              all the students in a selected group). If the test needs to be
              opened for a particular student, then click the
              <strong>[Test availability]</strong> icon beside him (blue color
              icon - test is closed, white - test is opened).
            </li>
            <li>
              Configure test availability and press the
              <strong>[Close]</strong> button to close the window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to close a test for students to pass?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Test availability]</strong> icon.
            </li>
            <li>
              In the pop-up page select group, press the
              <strong>[Close for all]</strong> button (to close the test for all
              the students in a selected group). If the test needs to be closed
              for a particular student, then click the
              <strong>[Test availability]</strong> icon beside him (blue color
              icon - test is closed, white - test is opened).
            </li>
            <li>
              Configure test availability and press the
              <strong>[Close]</strong> button to close the window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to pass a test to verify it?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>
              Find the desired test and click the
              <strong>[Pass the test]</strong> icon.
            </li>
            <li>
              In the pop-up page press the <strong>[Next]</strong> button.
            </li>
            <li>
              The test will be opened for passing. Press the
              <strong>[Reply]</strong> button to save current answer or press
              the <strong>[Skip]</strong> button to not save the current answer
              and return to the question in the end.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to view test results?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>Go to the <strong>Test results</strong> tab.</li>
            <li>Select the group, tests, students.</li>
            <li>
              The results will be displayed for every student in every test.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to monitor the passing of the test?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Tests</strong> section.</li>
            <li>Enter the <strong>Test passing monitoring</strong> tab.</li>
            <li>
              The names of students who are currently passing the test will be
              displayed.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Course projects</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add a theme for a course project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Press the <strong>[Add theme]</strong> button.</li>
            <li>
              In the pop-up window fill in the fields, select the necessary
              groups and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit the theme for a course project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit project]</strong> icon.
            </li>
            <li>
              In the pop-up window edit the necessary information and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to find the necessary theme, student or group in a theme
                list?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Click the <strong>[Search project, student, group]</strong> field.
            </li>
            <li>Enter the search details.</li>
            <li>
              The system will perform a search using the entered information. To
              reset filtering, erase the data from the field.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to assign a course project theme to a student?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Find the necessary theme (which has not yet been assigned) and
              click the <strong>[Assign project]</strong> icon.
            </li>
            <li>
              In the pop-up window find your student and click the
              <strong>[Assign]</strong>
              icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to remove a course project theme from a student?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page..</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Find the necessary theme (which has been assigned to the student)
              and click the <strong>[Cancel assign]</strong> icon.
            </li>
            <li>
              In the pop-up window press the <strong>[Yes]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to delete a course project theme?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Delete project]</strong> icon.
            </li>
            <li>
              In the pop-up window press the <strong>[Delete]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to download a task sheet?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>
              Find the necessary theme (which has been assigned to the student)
              and click the <strong>[Download task sheet]</strong> icon.
            </li>
          </ol>
          <p>OR</p>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Download task sheet]</strong> icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit a task sheet?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              In the pop-up window select a template, groups, edit the contents
              of the task sheet and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to create a template for the task sheet?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              Enter the template name and press the
              <strong>[Save template]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to apply a task sheet template to a group?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              Select the template, groups and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add a stage completion percentage?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Percentages</strong> tab.</li>
            <li>Press the <strong>[Add stage]</strong> button</li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to set completion percentage of the course project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Percentage results</strong> tab.</li>
            <li>
              Click 2 times on the cell near the necessary student in the date
              percentage column.
            </li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to assign a grade to a course project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to <strong>Percentage results</strong> tab.</li>
            <li>
              Click 2 times on the cell near the necessary student in the
              <strong>Grade</strong> column.
            </li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to load Percentage results into MS Excel?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to <strong>Percentage results</strong> tab.</li>
            <li>Click the <strong>[Download excel-document]</strong> icon.</li>
            <li>
              Select the download location and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download all task sheets from a selected group in an
                archive?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to <strong>Percentage results</strong> tab.</li>
            <li>
              Click the
              <strong>[Download task sheets as an archive]</strong> icon.
            </li>
            <li>
              Select the download location and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to add consultation date?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Consultation visit</strong> tab.</li>
            <li>Press the <strong>[Date management]</strong> button.</li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to review the course project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Enter the course page.</li>
            <li>Go to the <strong>Course project</strong> section.</li>
            <li>Go to the <strong>Defence</strong> tab.</li>
            <li>
              Find the necessary student and click the profile to open the sent
              projects list.
            </li>
            <li>
              Find the necessary project, click the name of the attached file
              and download it to your device.
            </li>
            <li>
              After reviewing press the button with the necessary action:
              <strong>[Accept]</strong> or <strong>[Return]</strong> to return
              for revision, fill out the fields in the pop-up window and press
              the <strong>[Submit work]</strong> button.
            </li>
            <li>
              If you accidentally accepted the project &ndash; press the
              <strong>[Decline]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Graduation project</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Who has access to Graduation project section?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            <strong>Graduation project</strong> section can be access only by 3
            roles:
          </p>
          <p>
            - Teacher (head of GP, this is configured by the administrator);
          </p>
          <p>- Student (his graduation year matches current year);</p>
          <p>
            - SEC secretary (teacher, may not be head of GP, configured by the
            administrator).
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add graduation project theme?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              In the opened page press the <strong>[Add theme]</strong> button.
            </li>
            <li>
              In the pop-up window fill in the fields, select the groups and
              press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit graduation project theme?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              In the opened page find the necessary theme and click the
              <strong>[Edit]</strong> icon.
            </li>
            <li>
              In the pop-up window edit information and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to find the necessary theme, student or group in the theme
                list?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              Click the
              <strong>[Search for a theme, student, or group]</strong> field.
            </li>
            <li>Enter the search details.</li>
            <li>
              The system will perform a search using the entered information. To
              reset filtering, erase the data from the field.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to assign a graduation project theme to a student?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              Find the necessary theme (which has not yet been assigned) and
              press the <strong>[Assign]</strong> button.
            </li>
            <li>
              In the pop-up window find the student and click the
              <strong>[Assign]</strong> icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to remove a graduation project theme from a student?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              Find the necessary theme (which has been assigned) and press the
              <strong>[Cancel assign]</strong> button.
            </li>
            <li>
              In the pop-up window press the <strong>[Yes]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to delete a graduation project theme?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>
              Find the necessary theme and click the
              <strong>[Delete]</strong> icon.
            </li>
            <li>
              In the pop-up window press the <strong>[Yes]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to download a task sheet?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the
              top.
            </li>
            <li>
              Find the necessary theme (which has been assigned to the student)
              and click the <strong>[Download task sheet]</strong> icon.
            </li>
            <li>
              In the pop-up window select download location and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
          <p>OR</p>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Download task sheet]</strong> icon.
            </li>
            <li>
              In the pop-up window select download location and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> How to edit a task sheet? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              In the pop-up window select a template, groups, edit the contents
              of the task sheet and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to create a template for the task sheet?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the Task sheet tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              Enter the template name and press the
              <strong>[Save template]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to apply a task sheet template to a group?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Task sheet</strong> tab.</li>
            <li>
              Find the necessary theme and click the
              <strong>[Edit task sheet]</strong> icon.
            </li>
            <li>
              Select the template, groups and press the
              <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to add a stage completion percentage?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Percentages</strong> tab.</li>
            <li>Press the <strong>[Add stage]</strong> button.</li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to assign a grade to a graduation project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to <strong>Percentage results</strong> tab.</li>
            <li>
              Click 2 times on the cell near the necessary student in the
              <strong>Grade</strong> column.
            </li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to set completion percentage of the graduation project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Percentage results</strong> tab.</li>
            <li>
              Click 2 times on the cell near the necessary student in the date
              percentage column.
            </li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> How to add consultation date? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the <strong>Graduation project</strong> menu item in the top
              panel.
            </li>
            <li>Go to the <strong>Consultation visiting</strong> tab.</li>
            <li>Press the <strong>[Date management]</strong> button.</li>
            <li>
              Fill in the fields and press the <strong>[Save]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
