<div class="page-wrap">
  <div class="container">
    <table>
      <td class="col-first">
        <mat-select
          class="mselect"
          (selectionChange)="onValueChange($event)"
          [value]="locale"
        >
          <mat-option *ngFor="let locale of locales" [value]="locale">
            {{ locale.name }}
          </mat-option>
        </mat-select>
        <a class="cat" href="#">
          <img id="mascot-body" src="./../../assets/images/mascot-body.svg" />
          <img id="mascot-tail" src="./../../assets/images/mascot-tail.svg" />
        </a>
        <br />
        <img src="./../../assets/images/logo.png" class="logo" />
        <p>Care about the students</p>
        <div class="btn_back" title="Нажмите,чтобы вернуться назад">
          <button mat-raised-button color="basic" (click)="routeToLogin()">
            {{ 'general.back' | translate: 'Назад' }}
          </button>
        </div>
      </td>
    </table>
    <table>
      <td class="col-second">
        <h1 class="title">
          {{
            'text.about.text.1'
              | translate: 'Что из себя представляет система CATS'
          }}?
        </h1>
        <div class="text">
          <p>
            {{
              'text.about.text.2'
                | translate
                  : 'Обучающая система CATS предназначена для повышения качества подготовки
            специалистов дневной и заочной форм обучения, а также для самостоятельной работы. С точки зрения функциональных возможностей поддерживается работа в 4 ролях'
            }}:
          </p>
          <ul>
            <li>{{ 'text.about.text.3' | translate: 'Администратор' }}.</li>
            <li>{{ 'text.about.text.4' | translate: 'Преподаватель' }}.</li>
            <li>{{ 'text.about.text.5' | translate: 'Студент' }}.</li>
            <li>{{ 'text.about.text.6' | translate: 'Наблюдатель' }}.</li>
          </ul>
          <p>
            {{
              'text.about.text.7'
                | translate: 'Первые 3 роли подлежат аутентификации'
            }}.
          </p>
          <p>
            {{
              'text.about.text.8' | translate: 'Функциональности преподавателя'
            }}:
          </p>
          <ol>
            <li>
              {{
                'text.about.text.9'
                  | translate: 'Создание/редактирование/удаление предметов'
              }}.
            </li>
            <li>
              {{
                'text.about.text.10'
                  | translate
                    : 'Формирование предмета из различных блоков (новости, лекции и др.)'
              }}.
            </li>
            <li>
              {{
                'text.about.text.11'
                  | translate
                    : 'Прикрепление групп к предметам, разделение студентов на подгруппы'
              }}.
            </li>
            <li>
              {{
                'text.about.text.12'
                  | translate
                    : 'Организация проведения лекционных, практических, лабораторных занятий
              с прикреплением заданий и нужных материалов, ведением электронного журнала,
              формированием графика защиты работ, расчета рейтинговой оценки по предмету'
              }}.
            </li>
            <li>
              {{
                'text.about.text.13'
                  | translate
                    : 'Проверка присланных работ на плагиат путём сравнения их с работами,
              хранящимися в архиве текущего и прошлых семестров'
              }}.
            </li>
            <li>
              {{
                'text.about.text.14'
                  | translate
                    : 'Формирование тестов для контроля и самоконтроля знаний студентов, организация проведения
              автоматизированного тестирования, ведение статистики результатов пройденных тестов'
              }}.
            </li>
            <li>
              {{
                'text.about.text.15'
                  | translate
                    : 'Организация курсового и дипломного проектирования'
              }}.
            </li>
            <li>
              {{
                'text.about.text.16'
                  | translate
                    : 'Создание ЭУМК (электронного учебно-методического комплекса)  с автоматическим формированием учебной карты'
              }}.
            </li>
            <li>
              {{
                'text.about.text.17'
                  | translate
                    : 'Адаптивность обучения для определения пробелов в знаниях обучающегося и построение его
              индивидуальной траектории обучения'
              }}.
            </li>
            <li>
              {{
                'text.about.text.18'
                  | translate
                    : 'Создание интерактивного учебника со встроенным редактором и возможностью экспорта'
              }}.
            </li>
            <li>
              {{
                'text.about.text.19'
                  | translate
                    : 'Обмен сообщениями с пользователями системы (администратором, преподавателями и студентами)'
              }}.
            </li>
          </ol>
          <p>
            {{
              'text.about.text.20'
                | translate
                  : 'В роли студента доступен следующий набор функциональности'
            }}:
          </p>
          <ol>
            <li>
              {{
                'text.about.text.21'
                  | translate
                    : 'Просмотр/загрузка всей предоставленной информации (расписания занятий, новостей,
              заданий и т.д.) по учебным дисциплинам'
              }}.
            </li>
            <li>
              {{
                'text.about.text.22'
                  | translate
                    : 'Изучение учебно-методических материалов в ЭУМК и интерактивном учебнике'
              }}.
            </li>
            <li>
              {{
                'text.about.text.23'
                  | translate
                    : 'Прохождение тестов для контроля знаний, самообучения и адаптивного обучения'
              }}.
            </li>
            <li>
              {{
                'text.about.text.24'
                  | translate
                    : 'Отправка отчетов по лабораторным и практическим работам на защиту'
              }}.
            </li>
            <li>
              {{
                'text.about.text.25'
                  | translate
                    : 'Выбор тем для курсового и дипломного проектирования, отслеживание процентовок выполнения проектов'
              }}.
            </li>
            <li>
              {{
                'text.about.text.26'
                  | translate
                    : 'Обмен сообщениями с преподавателями и администратором'
              }}.
            </li>
            <li>
              {{
                'text.about.text.27'
                  | translate
                    : 'Просмотр личной статистики успеваемости по учебным дисциплинам'
              }}.
            </li>
          </ol>
          <p>
            {{
              'text.about.text.28'
                | translate
                  : 'В роли наблюдателя реализована функциональность просмотра статистики успеваемости,
						позволяющая родителям студентов, работникам деканата и кафедры посмотреть интересующую
						 информацию о результативности учебного процесса: количество пропущенных занятий,
						 количество защищенных работ, оценки за тесты, рейтинговые оценки студентов и др.
						 Для этого не требуется проходить авторизацию в системе, а лишь ввести номер интересующей
						 группы или фамилию студента'
            }}.
          </p>
        </div>
      </td>
    </table>
  </div>
</div>
