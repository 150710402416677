<div class="page-wrap">
  <h1>Руководство пользователя для студента</h1>
  <div class="container">
    <div class="manual-column">
      <h2>Расписание</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как создать событие в расписании?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Перейдите на страницу с расписанием, нажав на логотип
              &laquo;<strong>CATS</strong>&raquo; в верхнем левом углу экрана.
            </li>
            <li>Нажмите на любую <strong>ячейку</strong> под нужной датой.</li>
            <li>Заполните нужные данные.</li>
            <li>Нажмите на кнопку <strong>[Сохранить]</strong>.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как редактировать событие? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Найдите нужное событие и нажмите на кнопку
              <strong>&laquo;&hellip;&raquo;.</strong>
            </li>
            <li>
              Появятся варианты действий с данным событием: Редактировать и
              Удалить.
            </li>
            <li>Нажмите на пункт <strong>[Редактировать]</strong>.</li>
            <li>В появившемся окне отредактируйте нужные данные.</li>
            <li>Нажмите на кнопку <strong>[Сохранить].</strong></li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить событие? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Найдите нужное событие и нажмите на кнопку
              <strong>&laquo;&hellip;&raquo;.</strong>
            </li>
            <li>
              Появятся варианты действий с данным событием: Редактировать и
              Удалить.
            </li>
            <li>Нажмите на пункт <strong>[Удалить]</strong>.</li>
            <li>
              В появившемся окне нажмите на кнопку <strong>[Да] </strong>для
              удаления или нажмите на кнопку <strong>[Нет] </strong>для
              отмены<strong>.</strong>
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Предметы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как зайти в нужный предмет? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на <strong>Выберите предмет.</strong>
            </li>
            <li>В открывшемся списке предметов выберите нужный предмет.</li>
            <li>
              Будет открыта страница, где можно переходить в подразделы
              предмета.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Новости</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть новости определенного предмета?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>На открывшейся странице будут отображены новости.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Лекции</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать файлы, прикрепленные к лекции?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>
              Найдите нужную лекцию и нажмите на кнопку
              <strong>[Файлы]</strong>.
            </li>
            <li>
              В открывшемся окне отметьте нужные файлы и нажмите на кнопку
              <strong>[Скачать]</strong>.
            </li>
            <li>Отмеченные файлы будут загружены на устройство.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как узнать информацию о посещаемости лекций?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Перейдите на вкладку <strong>Посещение лекций</strong>.</li>
            <li>
              На открывшейся странице будет доступна таблица со списком
              студентов группы и посещаемостью лекций.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Практические занятия</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть список практических занятий и скачать файлы,
                прикрепленные к ним?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия.</strong>
            </li>
            <li>
              На открывшейся странице будут отображены практические занятия.
            </li>
            <li>
              Найдите нужное занятие и нажмите на кнопку
              <strong>[Файлы]</strong>.
            </li>
            <li>
              В открывшемся окне отметьте нужные файлы и нажмите на кнопку
              <strong>[Скачать]</strong>.
            </li>
            <li>Отмеченные файлы будут загружены на устройство.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть график защиты, статистику посещения и результаты
                практических занятий?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия.</strong>
            </li>
            <li>
              Перейдите на нужную вкладку:
              <strong
                >График защиты, Статистика посещения или Результаты.</strong
              >
            </li>
            <li>На открывшейся странице будет отображена нужная информация.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Лабораторные работы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть список лабораторных работ и скачать файлы,
                прикрепленные к ним?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>Будет открыта страница со списком лабораторных работ.</li>
            <li>
              Найдите нужную лабораторную работу и нажмите на кнопку
              <strong>[Файлы]</strong>.
            </li>
            <li>
              В открывшемся окне отметьте нужные файлы и нажмите на кнопку
              <strong>[Скачать]</strong>.
            </li>
            <li>Отмеченные файлы будут загружены на устройство.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как отправить лабораторную работу на проверку?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы</strong>.</li>
            <li>Перейдите на вкладку <strong>Защита работ.</strong></li>
            <li>Нажмите на кнопку <strong>[Загрузить работу]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Отправить работу]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать отправленную лабораторную работу на проверку?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы</strong>.</li>
            <li>Перейдите на вкладку <strong>Защита работ.</strong></li>
            <li>
              Найдите нужную работу и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне отредактируйте нужную информацию и нажмите на
              кнопку <strong>[Отправить работу]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как удалить отправленную лабораторную работу на проверку?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы</strong>.</li>
            <li>Перейдите на вкладку <strong>Защита работ.</strong></li>
            <li>
              Найдите нужную работу и нажмите на иконку
              <strong>[Удалить]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Удалить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть график защиты, статистику посещения и результаты
                практических занятий?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>
              Перейдите на нужную вкладку:
              <strong
                >График защиты, Статистика посещения или Результаты.</strong
              >
            </li>
            <li>На открывшейся странице будет отображена нужная информация.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>ЭУМК</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть существующий ЭУМК?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>ЭУМК.</strong></li>
            <li>Найдите нужный ЭУМК и нажмите на него.</li>
            <li>
              В новом окне будет открыт ЭУМК с разделами и подразделами. Нажмите
              на название нужного раздела или подраздела, чтобы просмотреть его.
            </li>
            <li>
              Чтобы закрыть ЭУМК, нажмите на кнопку <strong>[Отмена]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как пройти раздел адаптивного обучения?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>ЭУМК.</strong></li>
            <li>Найдите нужный ЭУМК и нажмите на него.</li>
            <li>
              В новом окне будет открыт ЭУМК. Нажмите на кнопку
              <strong>[Адаптивное обучение].</strong>
            </li>
            <li>
              В открывшемся окне выберите нужный алгоритм обучения:
              <strong>Базовый алгоритм обучения</strong> или
              <strong>Углубленный алгоритм обучения. </strong>
            </li>
          </ol>
          <p>Если Вы выбрали Базовый алгоритм обучения:</p>
          <ol>
            <li>
              (Если до этого Вы не проходили адаптивное обучение) В открывшемся
              окне нажмите на кнопку <strong>[Пройти предтест]</strong>.
            </li>
            <li>
              (Если до этого Вы не проходили адаптивное обучение) Пройдите тест
              выберите ответ, нажмите на кнопку <strong>[Ответить]</strong> для
              выбора ответа или <strong>[Пропустить]</strong>, чтобы оставить
              данный вопрос на конец тестирования.
            </li>
            <li>
              (Если до этого Вы не проходили адаптивное обучение) После ответа
              на все вопросы нажмите на кнопку
              <strong>[Завершить тест] </strong>для перехода к обучению.
            </li>
            <li>
              Будет открыт следующий раздел ЭУМК, изучите его и затем пройдите
              тест по изученному материалу.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>Если Вы выбрали Углубленный алгоритм обучения:</p>
          <ol>
            <li>
              Ознакомьтесь с документами, для перехода к следующему документу
              нажмите на кнопку <strong>[Следующий документ]</strong> (или на
              кнопку <strong>[Предыдущий документ]</strong> для перехода к
              предыдущему).
            </li>
            <li>
              После ознакомления со всеми документами раздела и по истечении
              минимально заданного времени будет доступна кнопка
              <strong>[К тестам] </strong>для перехода к тестам. Нажмите на
              данную кнопку.
            </li>
            <li>
              В открывшемся тесте выберите нужный ответ на вопрос и нажмите на
              кнопку <strong>[Ответить]</strong> для выбора ответа или
              <strong>[Пропустить]</strong>, чтобы оставить данный вопрос на
              конец тестирования.
            </li>
            <li>
              После ответа на все вопросы нажмите на кнопку
              <strong>[Завершить тест]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Интерактивный учебник</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как открыть интерактивный учебник?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник в списке всех учебников и нажмите на него.
            </li>
            <li>Будет открыт выбранный учебник.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="manual-column">
      <h2>Файлы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать все файлы, прикрепленные к данному предмету?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Файлы.</strong></li>
            <li>
              Нажмите на иконку <strong>[Скачать архивом] </strong>для загрузки
              архивом всех файлов.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать нужные файлы, прикрепленные к предмету?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Файлы.</strong></li>
            <li>
              Нажмите на нужный файл, выберите место загрузки файла на
              устройство.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Тестирование знаний</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как пройти тест? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест (если нужного теста нет в списке &ndash;
              значит преподаватель не открыл к нему доступ) и нажмите на кнопку
              <strong>[Пройти тест]</strong>.
            </li>
            <li>
              На открывшейся странице нажмите на кнопку
              <strong>[Далее].</strong>
            </li>
            <li>
              В открывшемся тесте выберите вариант ответа и нажмите на кнопку
              <strong>[Ответить]</strong> (для сохранения выбранного ответа) или
              на кнопку <strong>[Пропустить]</strong> (чтобы пропустить вопрос и
              ответить на него позже).
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть результаты тестирования?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Перейдите на вкладку <strong>Результаты тестирования</strong>.
            </li>
            <li>
              На открывшейся странице выберите нужные виды тестов<strong
                >.</strong
              >
            </li>
            <li>
              На странице будут отображены результаты тестирования по выбранным
              тестам.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Курсовое проектирование</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть и выбрать тему курсового проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            Выбрать тему курсового проекта можно, если ни одна тема не была
            выбрана Вами или не была назначена преподавателем.
          </p>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              На странице будут отображены все темы для курсовых проектов.
            </li>
            <li>
              Найдите нужную тему и нажмите на иконку
              <strong>[Выбрать тему]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Выбрать].</strong>
            </li>
            <li>
              Данная тема будет назначена Вам. Для отмены назначения обратитесь
              к преподавателю данного предмета.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как найти нужную тему, студента или группу в списке тем?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Нажмите на поле <strong>[Поиск темы, студентов, группы]</strong>.
            </li>
            <li>Введите данные для поиска<strong>.</strong></li>
            <li>
              Система выполнит поиск по введенной информации. Для сброса
              фильтрации сотрите введенные данные из поля.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как скачать лист задания? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Скачать лист задания]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть график процентовки, результаты процентовки,
                статистику посещения консультаций?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на нужную вкладку:
              <strong
                >График процентовки, Результаты процентовки, Посещение
                консультаций.</strong
              >
            </li>
            <li>На открывшейся странице будет отображена нужная информация.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как отправить на защиту курсовой проект?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Защита проектов.</strong></li>
            <li>Нажмите на кнопку <strong>[Загрузить проект]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Отправить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать отправленный курсовой проект на проверку?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование</strong>.
            </li>
            <li>Перейдите на вкладку <strong>Защита проектов.</strong></li>
            <li>
              Найдите нужную работу и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне отредактируйте нужную информацию и нажмите на
              кнопку <strong>[Отправить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как удалить отправленный курсовой проект на проверку?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование</strong>.
            </li>
            <li>Перейдите на вкладку <strong>Защита проектов.</strong></li>
            <li>
              Найдите нужную работу и нажмите на иконку
              <strong>[Удалить проектов]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Удалить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Дипломное проектирование</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Почему мне не доступен раздел&nbsp;<strong
                  >Дипломное проектирование</strong
                >?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            Раздел <strong>Дипломное проектирование</strong> доступен только тем
            студентам, которые обучаются в группе, год окончания учебы которой
            совпадает с текущим годом.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть и выбрать тему дипломного проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            Выбрать тему дипломного проекта можно, если ни одна тема не была
            выбрана Вами или не была назначена преподавателем.
          </p>
          <ol>
            <li>
              Нажмите на раздел <strong>Дипломное проектирование </strong>в
              верхней панели<strong>.</strong>
            </li>
            <li>
              На странице будут отображены все темы для дипломных проектов.
            </li>
            <li>
              Найдите нужную тему и нажмите на иконку
              <strong>[Выбрать]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Выбрать].</strong>
            </li>
            <li>
              Данная тема будет назначена Вам. Для отмены назначения обратитесь
              к преподавателю данного предмета.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как найти нужную тему, студента или группу в списке тем?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на раздел <strong>Дипломное проектирование </strong>в
              верхней панели<strong>.</strong>
            </li>
            <li>
              Нажмите на поле <strong>[Поиск темы, студентов, группы]</strong>.
            </li>
            <li>Введите данные для поиска<strong>.</strong></li>
            <li>
              Система выполнит поиск по введенной информации. Для сброса
              фильтрации сотрите введенные данные из поля.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как скачать лист задания? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на раздел <strong>Дипломное проектирование </strong>в
              верхней панели<strong>.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Скачать лист задания]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть график процентовки, результаты процентовки,
                статистику посещения консультаций?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на раздел <strong>Дипломное проектирование </strong>в
              верхней панели<strong>.</strong>
            </li>
            <li>
              Перейдите на нужную вкладку:
              <strong
                >График процентовки, Результаты процентовки, Посещение
                консультаций.</strong
              >
            </li>
            <li>На открывшейся странице будет отображена нужная информация.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
