<div class="page-wrap">
  <h1>User manual for students</h1>
  <div class="container">
    <div class="manual-column">
      <h2>Schedule</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                What steps are necessary to create an event in the timetable?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click the &laquo;<strong>CATS</strong>&raquo; logo at the left
              corner of the screen to move to the schedule page.
            </li>
            <li>Click on any cell for the certain date.</li>
            <li>Fill in the required data.</li>
            <li>Click the <strong>[Save]</strong> button to save changes.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to edit an event?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Find the desired event and click the
              <strong>&laquo;&hellip;&raquo;</strong> button.
            </li>
            <li>
              The following options such as <strong>Edit</strong> and
              <strong>Delete</strong>
              appear.
            </li>
            <li>Click the <strong>[Edit]</strong> option.</li>
            <li>In the appeared window edit the necessary data.</li>
            <li>
              Click the <strong>[Save]</strong> button to save your changes.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to delete an event?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Find the required event and click the
              <strong>&laquo;&hellip;&raquo;</strong> button.
            </li>
            <li>
              The following options such as <strong>Edit</strong> and
              <strong>Delete</strong> appear.
            </li>
            <li>Click the <strong>[Delete]</strong> option.</li>
            <li>
              In the appeared window, click the <strong>[Yes]</strong> button to
              delete or click the <strong>[No]</strong> button to cancel.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Courses</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to navigate to the right course?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click on the <strong>[Courses]</strong> button in the top bar.
            </li>
            <li>
              Click on <strong>[Select an item]</strong> in the opened panel.
            </li>
            <li>Select the required course in the list of course.</li>
            <li>
              It’s possible to navigate to subsections of the
              <strong>Course</strong> in the opened page.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>News</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to check the news of the particular course?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>News</strong> subsection.</li>
            <li>The news is on the opened page.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Lectures</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download the files attached to a lecture?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Navigate to the <strong>Lectures</strong> subsection.</li>
            <li>
              Find the required lecture and press the
              <strong>[Files]</strong> icon.
            </li>
            <li>
              In the opened window, mark the necessary files and press the
              <strong>[Download]</strong> button.
            </li>
            <li>The marked files are downloaded to your device.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to find out the information about lectures attendance?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the right course.</li>
            <li>Move to the <strong>Лекции</strong> subsection.</li>
            <li>Click on the <strong>Lectures attendance</strong> tab.</li>
            <li>
              When the page opens, the table displaying group's students and
              their lecture attendance is present.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Workshops</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the list of workshops and download the attached
                files?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the right course.</li>
            <li>Move to the <strong>Workshops</strong> subsection.</li>
            <li>On the opened page workshops are available.</li>
            <li>
              Find the desired workshop and click the
              <strong>[Files]</strong> button.
            </li>
            <li>
              In the opened window select the desired files and click the
              <strong>[Download]</strong> button.
            </li>
            <li>The marked files are downloaded to the device.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the Submission schedule, Lesson attendance, and
                Workshops results?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Workshops</strong> subsection.</li>
            <li>
              Navigate to the
              <strong>Submission schedule</strong>,
              <strong>Lesson attendance</strong> or <strong>Results</strong>
              tab.
            </li>
            <li>The required information is on the opened page.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Laboratory works</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the list of laboratory works and download the
                attached files?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Laboratory works</strong> subsection.</li>
            <li>The page with the list of labs opens.</li>
            <li>
              Find the required laboratory work and click the
              <strong>[Files]</strong> button.
            </li>
            <li>
              Mark the required files and click the
              <strong>[Download]</strong> button in the opened window.
            </li>
            <li>The marked files are downloaded to the device.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to submit a laboratory work for a review?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Laboratory works</strong> subsection.</li>
            <li>Click on the <strong>Works submission</strong> tab.</li>
            <li>Click the <strong>[Upload the work]</strong> button.</li>
            <li>
              Fill in the fields and click the
              <strong>[Send the work]</strong> button in the opened window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit the submitted laboratory work for a review?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Laboratory works</strong> subsection.</li>
            <li>Click on the <strong>Works submission</strong> tab.</li>
            <li>
              Find the required work and click on the
              <strong>[Edit]</strong> icon.
            </li>
            <li>
              Edit the required information in the opened window and press the
              <strong>[Submit Paper]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to delete the submitted laboratory work for a review?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Laboratory works</strong> subsection.</li>
            <li>Click on the <strong>Works submission</strong> tab.</li>
            <li>
              Find the required work and click on the
              <strong>[Delete]</strong> icon.
            </li>
            <li>
              Click the <strong>[Delete]</strong> button in the appeared window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the Submission schedule, Lesson attendance, and
                Laboratory works results?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Laboratory works</strong> subsection.</li>
            <li>
              Press the
              <strong>Submission schedule</strong>,
              <strong>Lesson attendance</strong> или <strong>Results</strong>
              tab.
            </li>
            <li>The page that opens displays the necessary information.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Educational complex</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view an existing Educational complex?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>
              Move to the <strong>Educational complex</strong> subsection.
            </li>
            <li>Find the required educational complex and click on it.</li>
            <li>
              The educational complex with sections and subsections is available
              in the new window. Click on the name of the required section or
              subsection to view it.
            </li>
            <li>
              Click the <strong>[Cancel]</strong> button to close the
              educational complex.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to navigate to the adaptive learning section?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>
              Move to the <strong>Educational complex</strong> subsection.
            </li>
            <li>Find the required educational complex and click on it.</li>
            <li>
              The educational complex opens in a new window. Click the
              <strong>[Adaptive Learning]</strong> button.
            </li>
            <li>
              Select the desired learning algorithm: The advanced
              <strong>Learning Algorithm</strong> or
              <strong>The basic Learning Algorithm</strong> in the appeared
              window.
            </li>
          </ol>
          <p>If You have choosen the Basic Learning Algorithm:</p>
          <ol>
            <li>
              (If you have not taken adaptive learning before) Press
              <strong>[Take the pre-test]</strong> button in the opened window.
            </li>
            <li>
              (If you have not taken adaptive learning before) Pass the test
              select an answer, click the <strong>[Answer]</strong> button to
              select an answer, or click the <strong>[Skip]</strong> button to
              leave a question for the end of a test.
            </li>
            <li>
              (If you have not taken adaptive learning before) After answering
              all the questions, click the
              <strong>[Complete Test]</strong> button to proceed to the
              learning.
            </li>
            <li>
              The next section of the educational complex opens. Study it and
              take a quiz on the material you have learnt.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>If You have chosen the Advanced Learning Algorithm:</p>
          <ol>
            <li>
              Familiarize with documents. Click the
              <strong>[Next Document]</strong> button to move to the next
              document (or click the <strong>[Previous Document]</strong> button
              to move to the previous one).
            </li>
            <li>
              After you have become familiar with every document in the section
              and the pre-set amount of time has gone by, the
              <strong>[To Tests]</strong> button is enabled so that you can move
              to the tests.
            </li>
            <li>
              After the test opens, select the necessary answer to a question
              and press the <strong>[Answer]</strong> button to select the
              answer or the <strong>[Skip]</strong>
              button to leave this question for the end of the test.
            </li>
            <li>
              When you have answered all the questions, click the
              <strong>[End Test]</strong>
              button.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Interactive tutorial</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title
                >How to open an interactive tutorial?</mat-panel-title
              >
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>
              Move to the <strong>Interactive tutorial</strong> subsection.
            </li>
            <li>
              Find the required interactive tutorial in the list of all
              textbooks and click on it.
            </li>
            <li>The selected interactive tutorial opens.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="manual-column">
      <h2>Files</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download all the files attached to a given Course?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Files</strong> subsection.</li>
            <li>
              Click on the <strong>[Download an archive]</strong> icon to
              download all files in an archive.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to download the required files attached to a Course?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Files</strong> subsection.</li>
            <li>
              Click on the desired file, select the download location of the
              file in your device.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Tests</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title>How to take a test?</mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Tests</strong> subsection.</li>
            <li>
              Find the required test (if the required test is not on the list,
              it means that a teacher hasn’t opened access to it) and click the
              <strong>[Take a test]</strong> button.
            </li>
            <li>
              Press the <strong>[Next]</strong> button in the opened page.
            </li>
            <li>
              In the opened test, select an answer option and click the
              <strong>[Answer]</strong> button (to save the selected answer) or
              the <strong>[Skip]</strong> button (to skip the question and
              answer it later).
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to view my test results?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Tests</strong> subsection.</li>
            <li>Click the <strong>Test Results</strong> tab.</li>
            <li>Select the required types of tests on the appeared page.</li>
            <li>The page displays the test results for the selected tests.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Course projects</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view and select the topic for my term project?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            You can select a topic for your course project if You haven’t chosen
            any topic or Your teacher hasn’t assigned one to You.
          </p>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>The page displays all the topics for course.</li>
            <li>
              Find the required topic and click on the
              <strong>[Select the topic]</strong>
              icon.
            </li>
            <li>
              Click the <strong>[Select]</strong> button on the opened page.
            </li>
            <li>
              The given topic assigns to You, to cancel the assignment contact
              the teacher of this course.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to find the right course, student, or group in the topics
                list?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>
              Click on the
              <strong>[Search for a topic, students, a group]</strong> field.
            </li>
            <li>Enter the information You want to search for.</li>
            <li>
              The system searches based on the information entered. To reset
              filtering, erase the entered data from the field.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to download a task sheet?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the required course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>Click on the <strong>Task sheet</strong> tab.</li>
            <li>
              Select the required topic and click on the
              <strong>[Download the assignment sheet]</strong> icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the percentages, percentage results, consultation
                visit statistics?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the desired course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>
              Click on the <strong>Percentages</strong>,
              <strong>Percentage Results</strong> or
              <strong>Consultation visit</strong> tab.
            </li>
            <li>The opened page displays the required information.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to send a course project for submitting?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the desired course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>Click on the <strong>Work submission</strong> tab.</li>
            <li>Click on the <strong>[Upload the Work]</strong> button.</li>
            <li>
              Fill in the fields and press the
              <strong>[Submit the Work]</strong> button in the opened window.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to edit a submitted course project for a review?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the desired course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>Click on the <strong>Work submission</strong> tab.</li>
            <li>
              Find the required work and click on the
              <strong>[Edit]</strong> icon.
            </li>
            <li>
              In the opened window, edit the required information and press the
              <strong>[Submit Paper]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to delete a submitted course project for a review?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Navigate to the desired course.</li>
            <li>Move to the <strong>Course projects</strong> subsection.</li>
            <li>Click on the <strong>Work submission</strong> tab.</li>
            <li>
              Find the required work and click on the
              <strong>[Delete project]</strong> icon.
            </li>
            <li>
              In the opened window, click the <strong>[Delete]</strong> button.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Graduation project</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Why is the&nbsp;<strong>Graduation project</strong>&nbsp;section
                unavailable to me?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            The <strong>Graduation project</strong> section is only available to
            students whose graduation year is the same as the current year.
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view and select a graduation project theme?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            You can select a graduation project theme if You hasn’t selected any
            theme or Your teacher hasn’t assigned one to You.
          </p>
          <ol>
            <li>
              Click on the <strong>Graduation project</strong> section in the
              top bar.
            </li>
            <li>The page displays all the themes for graduation projects.</li>
            <li>
              Find the theme you want and click on the
              <strong>[Select]</strong> icon.
            </li>
            <li>
              In the opened window, click the <strong>[Select]</strong> button.
            </li>
            <li>
              The given theme assigns to You, to cancel the assignment contact
              the teacher of this course.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to find the necessary theme, student, or group in the theme
                list?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click on the <strong>Graduation project</strong> section in the
              top bar.
            </li>
            <li>
              Click on the
              <strong>[Search for a theme, students, group]</strong> field.
            </li>
            <li>Enter the information you want to search for.</li>
            <li>
              The system searches based on the information entered. To reset the
              filtering, erase the entered data from the field.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>How to download the task sheet?</mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click on the <strong>Graduation project</strong> section in the
              top bar.
            </li>
            <li>Move to the <strong>Task sheet</strong> tab.</li>
            <li>
              Select the desired topic and click on the
              <strong>[Download the task sheet]</strong> icon.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                How to view the percentages, percentage results, consultation
                visit statistics?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Click on the <strong>Graduation project</strong> section in the
              top bar.
            </li>
            <li>
              Click on the <strong>Percentages</strong>,
              <strong>Percentage Results</strong> or
              <strong>Consultation</strong> visit tab.
            </li>
            <li>The opened page displays the information you need.</li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
