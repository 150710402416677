<div class="page-wrap">
  <h1>Руководство пользователя для преподавателя</h1>
  <div class="container">
    <div class="manual-column">
      <h2>Расписание</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как создать событие в расписании?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Перейдите на страницу с расписанием, нажав на логотип
              &laquo;<strong>CATS</strong>&raquo; в верхнем левом углу экрана.
            </li>
            <li>Нажмите на любую <strong>ячейку</strong> под нужной датой.</li>
            <li>
              В появившемся окне выберите <strong>Занятие</strong> или
              <strong>Событие</strong>.
            </li>
            <li>Заполните нужные данные.</li>
            <li>Нажмите на кнопку <strong>[Сохранить]</strong>.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как редактировать событие? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Найдите нужное <strong>Событие</strong> и нажмите на кнопку
              <strong>&laquo;&hellip;&raquo;</strong>.
            </li>
            <li>
              Появятся варианты действий с данным событием:
              <strong>Редактировать</strong> и <strong>Удалить</strong>.
            </li>
            <li>Нажмите на пункт <strong>[Редактировать]</strong>.</li>
            <li>В появившемся окне отредактируйте нужные данные.</li>
            <li>Нажмите на кнопку <strong>[Сохранить]</strong>.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить событие? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Найдите нужное событие и нажмите на кнопку
              <strong>&laquo;&hellip;&raquo;</strong>.
            </li>
            <li>
              Появятся варианты действий с данным событием:
              <strong>Редактировать</strong> и <strong>Удалить</strong>.
            </li>
            <li>Нажмите на пункт <strong>[Удалить]</strong>.</li>
            <li>
              В появившемся окне нажмите на кнопку <strong>[Да]</strong> для
              удаления или нажмите на кнопку <strong>[Нет]</strong> для отмены.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Предметы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как создать предмет? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на кнопку
              <strong>[Управление предметами].</strong>
            </li>
            <li>
              На открывшейся странице нажмите на кнопку
              <strong>[Добавить предмет]</strong>.
            </li>
            <li>
              Заполните поля. Обратите внимание, что модуль
              <strong>Новости</strong> включен в предмет автоматически. В любой
              момент времени после сохранения предмета Вы сможете
              отредактировать его наполнение модулями и другой информацией.
            </li>
            <li>Нажмите на кнопку [Сохранить].</li>
            <li>
              Добавленный предмет отобразится на странице со списком предметов.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как редактировать предмет? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на кнопку
              <strong>[Управление предметами].</strong>
            </li>
            <li>
              На открывшейся странице нажмите на иконку
              <strong>[Редактировать предмет]</strong>.
            </li>
            <li>
              Измените данные в нужных полях и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
            <li>
              Отредактированный предмет отобразится с обновленной информацией на
              странице со списком предметов.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить предмет? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на кнопку
              <strong>[Управление предметами].</strong>
            </li>
            <li>
              На открывшейся странице нажмите на иконку
              <strong>[Удалить предмет]</strong>.
            </li>
            <li>
              В появившемся окне нажмите на кнопку <strong>[Удалить]</strong>.
            </li>
            <li>
              Данный предмет будет удален и не отобразится на странице со
              списком предметов.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как присоединить преподавателя к предмету?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на кнопку
              <strong>[Управление предметами].</strong>
            </li>
            <li>
              Найдите нужный предмет на открывшейся странице и нажмите на иконку
              <strong>[Присоединить преподавателя]</strong>.
            </li>
            <li>
              В открывшемся окне выберите нужного преподавателя и нажмите на
              кнопку <strong>[Присоединить]</strong>.
            </li>
            <li>
              Выбранный преподаватель присоединен к предмету и может им
              управлять.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как зайти в нужный предмет? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Предметы</strong> в верхней панели.
            </li>
            <li>
              В открывшейся панели нажмите на
              <strong>[Выберите предмет]</strong>.
            </li>
            <li>В открывшемся списке предметов выберите нужный предмет.</li>
            <li>
              Будет открыта страница, где можно переходить в подразделы предмета
              и делать там изменения.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Новости</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как создать новость? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>Нажмите на кнопку <strong>[Добавить новость]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
            <li>Добавленная новость отобразится в списке новостей.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как редактировать новость? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>
              Наведите на нужную новость и нажмите на иконку
              <strong>[Редактировать новость]</strong>.
            </li>
            <li>
              В открывшемся окне измените информацию в нужных полях и нажмите на
              кнопку <strong>[Сохранить]</strong>.
            </li>
            <li>
              Отредактированная новость отобразится в списке новостей в
              обновленном виде.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить новость? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>
              Наведите на нужную новость и нажмите на иконку
              <strong>[Удалить новость]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Удалить]</strong>.
            </li>
            <li>Новость будет удалена и не отобразится в списке новостей.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скрыть все новости на главной странице?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>Нажмите на кнопку <strong>[Скрыть все новости]</strong>.</li>
            <li>
              Все новости будут скрыты из списка новостей на главной странице.
              Скрытая новость отображается с &laquo;серой полосой&raquo; левее
              от блока новости.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как показать все новости на главной странице?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Новости.</strong></li>
            <li>Нажмите на кнопку <strong>[Показать все новости]</strong>.</li>
            <li>
              Все новости будут отображены в списке новостей на главной
              странице. Не скрытая новость отображается с тем цветом
              &laquo;полосы&raquo; левее от блока новости, который установлен на
              предмет.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Лекции</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как добавить лекцию? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>

            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Нажмите на кнопку <strong>[Добавить лекцию]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
            <li>Добавленная лекция будет отображаться в списке лекций.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить или удалить даты лекций?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Перейдите на вкладку<strong> Посещение лекций.</strong></li>
            <li>
              Нажмите на кнопку <strong>[Управление расписанием]</strong>.
            </li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Добавить] </strong>или нажмите на иконку
              <strong>[Удалить] </strong>для удаления даты лекции.
            </li>
            <li>
              Добавленные или удаленные даты лекций будут отображаться в таблице
              посещаемости лекций.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как удалить все даты лекций? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Перейдите на вкладку<strong> Посещение лекций.</strong></li>
            <li>Нажмите на кнопку <strong>[Удалить все даты]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Удалить]</strong>.
            </li>
            <li>Все даты лекций будут удалены.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как отметить посещаемость на лекции?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Перейдите на вкладку<strong> Посещение лекций.</strong></li>
            <li>Нажмите 2 раза на <strong>ячейку</strong> под нужной датой.</li>
            <li>
              В открывшемся окне отметьте посещаемость (количество пропущенных
              часов, комментарии) и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Практические занятия</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить практическое занятие?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия.</strong>
            </li>
            <li>Нажмите на кнопку <strong>[Добавить занятие]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
            <li>Добавленное занятие будет отображаться в списке занятий.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить или удалить даты практических занятий?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лекции.</strong></li>
            <li>Перейдите на вкладку<strong> График защиты.</strong></li>
            <li>Нажмите на кнопку <strong>[Управление датами]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Добавить] </strong>или нажмите на иконку
              <strong>[Удалить] </strong>для удаления даты занятия.
            </li>
            <li>
              Добавленные или удаленные даты занятий будут отображаться в
              таблице.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как отметить посещаемость на практическом занятии?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия.</strong>
            </li>
            <li>Перейдите на вкладку<strong> Статистика посещения.</strong></li>
            <li>Нажмите 2 раза на <strong>ячейку</strong> под нужной датой.</li>
            <li>
              В открывшемся окне отметьте посещаемость (количество пропущенных
              часов, комментарии) и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как выставить оценки на практическом занятии?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия.</strong>
            </li>
            <li>Перейдите на вкладку<strong> Результаты.</strong></li>
            <li>Нажмите 2 раза на <strong>ячейку</strong> под нужной датой.</li>
            <li>
              В открывшемся окне выставьте оценки (оценки, комментарии) и
              нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как разделить группу на подгруппы?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Практические занятия</strong>.
            </li>
            <li>
              Перейдите на вкладку <strong>График защиты</strong>,
              <strong>Статистика посещения</strong> или
              <strong>Результаты</strong>.
            </li>
            <li>
              Нажмите на иконку <strong>[Разделение на подгруппы]</strong>.
            </li>
            <li>
              В открывшемся окне выберите группу, нужных студентов и номер
              подгруппы, в которую необходимо отнести данных студентов, и
              нажмите на кнопку <strong>[Сохранить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Лабораторные работы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить лабораторную работу?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>Нажмите на кнопку <strong>[Добавить работу]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
            <li>Добавленная работа будет отображаться в списке занятий.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить или удалить даты лабораторных работ?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>Перейдите на вкладку<strong> График защиты.</strong></li>
            <li>Нажмите на кнопку <strong>[Управление датами]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Добавить] </strong>или нажмите на иконку
              <strong>[Удалить] </strong>для удаления даты занятия.
            </li>
            <li>
              Добавленные или удаленные даты занятий будут отображаться в
              таблице.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как отметить посещаемость на лабораторной работе?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>Перейдите на вкладку<strong> Статистика посещения.</strong></li>
            <li>Нажмите 2 раза на <strong>ячейку</strong> под нужной датой.</li>
            <li>
              В открывшемся окне отметьте посещаемость (количество пропущенных
              часов, комментарии) и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как проверить лабораторную работу, присланную студентом?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Лабораторные работы.</strong></li>
            <li>Перейдите на вкладку<strong> Защита работ.</strong></li>
            <li>
              Найдите нужного студента и нажмите на него, чтобы открыть список
              присланных работ.
            </li>
            <li>
              Найдите нужную работу, нажмите на название прикрепленного файла и
              загрузите его на устройство.
            </li>
            <li>
              После проверки нажмите на кнопку с нужным действием:
              <strong>[Принять] </strong>или <strong>[Вернуть] </strong>(чтобы
              вернуть на доработку, для этого заполните поля в открывшемся окне
              и нажмите на кнопку <strong>[Отправить работу].</strong>
            </li>
            <li>
              Если по ошибке приняли работу &ndash; нажмите на кнопку
              <strong>[Отклонить работу]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Файлы</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать все файлы, прикрепленные к данному предмету?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Файлы.</strong></li>
            <li>
              Нажмите на иконку <strong>[Скачать архивом] </strong>для загрузки
              архивом всех файлов.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать нужные файлы, прикрепленные к предмету?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Файлы.</strong></li>
            <li>
              Нажмите на нужный файл, выберите место загрузки файла на
              устройство.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>ЭУМК</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как просмотреть существующий ЭУМК?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>ЭУМК.</strong></li>
            <li>Найдите нужный ЭУМК и нажмите на него.</li>
            <li>В новом окне будет открыт ЭУМК, который можно просмотреть.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как создать новый ЭУМК? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>ЭУМК.</strong></li>
            <li>Нажмите на кнопку <strong>[+</strong><strong>]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Интерактивный учебник</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как добавить новый учебник? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>Нажмите на кнопку <strong>[+</strong><strong>]</strong>.</li>
            <li>
              В открывшемся окне введите имя учебника и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как переименовать учебник? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник и нажмите
              <strong>на него правой кнопкой мыши</strong>.
            </li>
            <li>
              В открывшемся меню нажмите на кнопку
              <strong>[Переименовать]</strong>.
            </li>
            <li>
              В открывшемся окне переименуйте учебник и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить учебник? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник и нажмите
              <strong>на него правой кнопкой мыши</strong>.
            </li>
            <li>
              В открывшемся меню нажмите на кнопку <strong>[Удалить]</strong>.
            </li>
            <li>В открывшемся окне нажмите на кнопку <strong>[Да]</strong>.</li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить новую тему в учебник?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник и нажмите
              <strong>на него правой кнопкой мыши</strong>.
            </li>
            <li>
              В открывшемся меню нажмите на кнопку
              <strong>[Добавить новую тему]</strong>.
            </li>
            <li>
              В открывшемся окне введите название темы и нажмите на кнопку
              <strong>[Сохранить]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как закрыть или открыть учебник для пользователей?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник и нажмите
              <strong>на него правой кнопкой мыши</strong>.
            </li>
            <li>
              В открывшемся меню нажмите на кнопку
              <strong>[Закрыть доступ]</strong>(если учебник открыт для
              пользователей) или <strong>[Открыть доступ]</strong>(если ранее
              учебник был закрыт для пользователей).
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как заполнить содержанием учебник?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Интерактивный учебник.</strong>
            </li>
            <li>
              Найдите нужный учебник, выберите тему для заполнения контентом и
              нажмите на иконку <strong>[Редактировать содержимое]</strong> в
              правой части учебника.
            </li>
            <li>
              Будет открыт доступ к редактированию содержимого учебника,
              заполните его необходимой информацией.
            </li>
            <li>
              После завершения редактирования нажмите на кнопку
              <strong>[Сохранить] </strong>для сохранения или на кнопку<strong>
                [Отмена]</strong
              >, чтобы изменения не были сохранены.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="manual-column">
      <h2>Настройки</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как открыть или закрыть доступ студенту к системе?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              На верхней панели нажмите на иконку
              <strong>[Новые студенты]</strong>. Иконка имеет в углу число
              студентов, которым закрыт доступ к системе.
            </li>
            <li>
              На открывшейся странице выберите нужную групп. Также можете с
              помощью поля для поиска найти нужного студента.
            </li>
            <li>
              Нажмите на иконку <strong>[Открыть доступ] </strong>(если студенту
              не был открыт доступ к системе ранее) или<strong>
                [Закрыть доступ] </strong
              >(если студенту ранее был открыт доступ к системе).
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать информацию в личном кабинете пользователя?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              На верхней панели нажмите на иконку
              <strong>[Личный кабинет]</strong>.
            </li>
            <li>
              На открывшейся странице отредактируйте нужную информацию (можете
              загрузить изображение, отредактировать информацию о себе).
            </li>
            <li>
              Нажмите на кнопку <strong>[Сохранить] </strong>для сохранения
              изменений или<strong> [Закрыть] </strong>для отмены изменений.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как изменить пароль? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              На верхней панели нажмите на иконку
              <strong>[Личный кабинет]</strong>.
            </li>
            <li>
              На открывшейся странице нажмите на ссылку
              <strong>[Изменить пароль]</strong>.
            </li>
            <li>
              В открывшемся окне введите старый и новый пароль и нажмите на
              кнопку <strong>[Изменить] </strong>для сохранения изменений
              или<strong> [Закрыть] </strong>для отмены изменений.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Тестирование знаний</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как добавить тест? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>Нажмите на кнопку <strong>[Добавить тест]</strong>.</li>
            <li>
              В открывшемся окне заполните поля и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как редактировать тест? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Редактировать тест]</strong>.
            </li>
            <li>
              В открывшемся окне отредактируйте нужные поля и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div><mat-panel-title> Как удалить тест? </mat-panel-title></div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Удалить тест]</strong>.
            </li>
            <li>В открывшемся окне нажмите на кнопку <strong>[Да].</strong></li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как добавить вопросы в тест? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Перейти к вопросам]</strong>.
            </li>
            <li>
              На открывшейся странице нажмите на кнопку
              <strong>[Добавить вопрос] </strong>(чтобы добавить новый вопрос)
              или нажмите на кнопку
              <strong>[Добавить вопрос из другого теста] </strong>(чтобы
              добавить вопрос из существующего теста).
            </li>
            <li>
              Заполните поля или выберите вопрос из существующего теста и
              нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать вопрос в тесте?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Перейти к вопросам]</strong>.
            </li>
            <li>
              На открывшейся странице найдите нужный вопрос и нажмите на иконку
              <strong>[Редактировать вопрос]</strong>.
            </li>
            <li>
              Отредактируйте нужные поля и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как удалить вопрос в тесте? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Перейти к вопросам]</strong>.
            </li>
            <li>
              На открывшейся странице найдите нужный вопрос и нажмите на иконку
              <strong>[Удалить вопрос]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как открыть тест для прохождения студентам?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Доступность теста]</strong>.
            </li>
            <li>
              На открывшейся странице выберите группу, нажмите на кнопку
              <strong>[Открыть для всех] </strong>(чтобы открыть тест для всех
              студентов данной группы)<strong>. </strong>Если необходимо открыть
              тест для прохождения определенному студенту, то напротив него
              нажмите на иконку <strong>[Доступность теста]</strong> (иконка
              синего цвета &ndash; цвет закрыт для прохождения, белого цвета
              &ndash; открыт для прохождения).
            </li>
            <li>
              Настройте доступность теста и нажмите на кнопку
              <strong>[Закрыть]</strong> для закрытия окна.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как закрыть тест для прохождения студентам?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Доступность теста]</strong>.
            </li>
            <li>
              На открывшейся странице выберите группу, нажмите на кнопку
              <strong>[Закрыть для всех] </strong>(чтобы закрыть тест для всех
              студентов данной группы)<strong>. </strong>Если необходимо закрыть
              тест для прохождения определенному студенту, то напротив него
              нажмите на иконку <strong>[Доступность теста]</strong> (иконка
              синего цвета &ndash; цвет закрыт для прохождения, белого цвета
              &ndash; открыт для прохождения).
            </li>
            <li>
              Настройте доступность теста и нажмите на кнопку
              <strong>[Закрыть]</strong> для закрытия окна.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как пройти тест в целях его проверки?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Найдите нужный тест в списке тестов и нажмите на иконку
              <strong>[Пройти тест]</strong>.
            </li>
            <li>
              На открывшейся странице нажмите на кнопку
              <strong>[Далее].</strong>
            </li>
            <li>
              Тест будет открыт для прохождения. Нажмите на кнопку
              <strong>[Далее]</strong> для сохранения данного ответа или на
              кнопку <strong>[Пропустить]</strong>, чтобы не сохранять данный
              ответ и вернуться к нему в конце прохождения теста.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как посмотреть результаты тестирования?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Перейдите на вкладку <strong>Результаты тестирования.</strong>
            </li>
            <li>Выберите группу, тесты, студентов.</li>
            <li>
              Будут отображены результаты тестирования каждого студента по
              каждому тесту.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как контролировать прохождение тестов студентами?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>Перейдите в подраздел <strong>Тестирование знаний.</strong></li>
            <li>
              Перейдите на вкладку <strong>Контроль прохождения тестов.</strong>
            </li>
            <li>
              Будут отображены ФИО студентов, которые в данный момент проходят
              тесты.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Курсовое проектирование</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить тему курсового проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование</strong>.
            </li>
            <li>Нажмите на кнопку <strong>[Добавить тему]</strong>.</li>
            <li>
              В открывшемся окне заполните поля, выберите нужные группы и
              нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать тему курсового проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Найдите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне отредактируйте нужную информацию и нажмите на
              кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как найти нужную тему, студента или группу в списке тем?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Нажмите на поле <strong>[Поиск темы, студентов, группы]</strong>.
            </li>
            <li>Введите данные для поиска<strong>.</strong></li>
            <li>
              Система выполнит поиск по введенной информации. Для сброса
              фильтрации сотрите введенные данные из поля.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как назначить тему курсового проекта студенту?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Найдите нужную тему (которая еще не была назначена) и нажмите на
              иконку <strong>[Назначить тему]</strong>.
            </li>
            <li>
              В открывшемся окне найдите нужного студента и нажмите на иконку
              <strong>[Назначить тему].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как снять назначение темы курсового проекта со студента?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Найдите нужную тему (которая была назначена студенту) и нажмите на
              иконку <strong>[Отменить назначение темы]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку
              <strong>[Да].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как удалить тему курсового проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Найдите нужную тему и нажмите на иконку
              <strong>[Удалить]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Удалить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как скачать лист задания? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              На вкладке <strong>Темы</strong> проектов найдите нужную тему
              (которая была назначена студенту) и нажмите на иконку
              <strong>[Скачать лист задания]</strong>.
            </li>
          </ol>
          <p>ИЛИ</p>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Скачать лист задания]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать лист задания?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне выберите шаблон, группы, отредактируйте
              содержание листа задания и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как создать шаблон для листа задания?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              Введите название шаблона и нажмите на кнопку
              <strong>[Сохранить шаблон].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как применить шаблон листа задания к группе?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              Выберите шаблон, группы и нажмите на кнопку
              <strong>[Применить шаблон].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить этап процентовки?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>График процентовки.</strong></li>
            <li>Нажмите на кнопку<strong> [Добавить этап]</strong>.</li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как выставить процент выполнения курсового проекта студентами?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите 2 раза <strong>на ячейку</strong> напротив нужного
              студента в колонке даты процентовки.
            </li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как выставить оценку по курсовому проекту?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите 2 раза <strong>на ячейку</strong> напротив нужного
              студента в колонке <strong>Оценка</strong>.
            </li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как загрузить результаты процентовки в MS Excel?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите на иконку <strong>[Загрузить документ </strong
              ><strong>Excel]</strong>.
            </li>
            <li>
              Выберите место загрузки и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как скачать архивом все листы заданий выбранной группы?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите на иконку
              <strong>[Скачать архивом листы заданий]</strong>.
            </li>
            <li>
              Выберите место загрузки и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить дату консультации?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>
              Перейдите на вкладку <strong>Посещение консультаций.</strong>
            </li>
            <li>Нажмите на кнопку <strong>[Управление датами]</strong>.</li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как проверить присланный курсовой проект?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>Зайдите в нужный предмет.</li>
            <li>
              Перейдите в подраздел <strong>Курсовое проектирование.</strong>
            </li>
            <li>Перейдите на вкладку <strong>Защита (проектов)</strong>.</li>
            <li>
              Найдите нужного студента и нажмите на него, чтобы открыть список
              присланных работ.
            </li>
            <li>
              Найдите нужную работу, нажмите на название прикрепленного файла и
              загрузите его на устройство.
            </li>
            <li>
              После проверки нажмите на кнопку с нужным действием:
              <strong>[Принять] </strong>или <strong>[Вернуть] </strong>(чтобы
              вернуть на доработку, для этого заполните поля в открывшемся окне
              и нажмите на кнопку <strong>[Отправить работу].</strong>
            </li>
            <li>
              Если по ошибке приняли работу &ndash; нажмите на кнопку
              <strong>[Отклонить работу]</strong>.
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>

      <h2>Дипломное проектирование</h2>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Кому доступен раздел Дипломное проектирование?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>Раздел Дипломное проектирование доступен 3 ролям:</p>
          <p>
            - преподаватель (руководитель ДП, это настраивается
            администратором);
          </p>
          <p>- студент (год его выпуска совпадает с текущим годом);</p>
          <p>
            - секретарь ГЭК (преподаватель, может и не быть руководителем ДП,
            настраивается администратором).
          </p>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить тему дипломного проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              На открывшейся странице нажмите на кнопку
              <strong>[Добавить тему]</strong>.
            </li>
            <li>
              В открывшемся окне заполните поля, выберите нужные группы и
              нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать тему дипломного проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              На открывшейся странице найдите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне отредактируйте нужную информацию и нажмите на
              кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как найти нужную тему, студента или группу в списке тем?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Нажмите на поле <strong>[Поиск темы, студентов, группы]</strong>.
            </li>
            <li>Введите данные для поиска<strong>.</strong></li>
            <li>
              Система выполнит поиск по введенной информации. Для сброса
              фильтрации сотрите введенные данные из поля.
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как назначить тему дипломного проекта студенту?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Найдите нужную тему (которая еще не была назначена) и нажмите на
              иконку <strong>[Назначить]</strong>.
            </li>
            <li>
              В открывшемся найдите нужного студента и нажмите на иконку
              <strong>[Назначить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как снять назначение темы курсового проекта со студента?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Найдите нужную тему (которая была назначена студенту) и нажмите на
              иконку <strong>[Отменить назначение]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку
              <strong>[Отменить назначение].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как удалить тему дипломного проекта?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Найдите нужную тему и нажмите на иконку
              <strong>[Удалить]</strong>.
            </li>
            <li>
              В открывшемся окне нажмите на кнопку <strong>[Удалить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title> Как скачать лист задания? </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Найдите нужную тему (которая была назначена студенту) и нажмите на
              иконку <strong>[Скачать лист задания]</strong>.
            </li>
            <li>
              В открывшемся окне выберите место для загрузки и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
          <p>ИЛИ</p>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Скачать лист задания]</strong>.
            </li>
            <li>
              В открывшемся окне выберите место для загрузки и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как редактировать лист задания?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              В открывшемся окне выберите шаблон, группы, отредактируйте
              содержание листа задания и нажмите на кнопку
              <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как создать шаблон для листа задания?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              Введите название шаблона и нажмите на кнопку
              <strong>[Создать шаблон].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как применить шаблон листа задания к группе?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>Перейдите на вкладку <strong>Лист задания.</strong></li>
            <li>
              Выберите нужную тему и нажмите на иконку
              <strong>[Редактировать]</strong>.
            </li>
            <li>
              Выберите шаблон, группы и нажмите на кнопку
              <strong>[Применить шаблон].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить этап процентовки?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <p>
            Этапы процентовки может добавить только преподаватель, который
            является секретарем ГЭК (данные права настраивает администратор).
          </p>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>Перейдите на вкладку <strong>График процентовки.</strong></li>
            <li>Нажмите на кнопку<strong> [Добавить этап]</strong>.</li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как выставить процент выполнения дипломного проекта студентами?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите 2 раза <strong>на ячейку</strong> напротив нужного
              студента в колонке даты процентовки.
            </li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как выставить оценку по дипломному проекту?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Перейдите на вкладку <strong>Результаты процентовки.</strong>
            </li>
            <li>
              Нажмите 2 раза <strong>на ячейку</strong> напротив нужного
              студента в колонке <strong>Оценка</strong>.
            </li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div>
              <mat-panel-title>
                Как добавить дату консультации?
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <ol>
            <li>
              Нажмите на пункт меню <strong>Дипломное проектирование</strong> в
              верхней панели.
            </li>
            <li>
              Перейдите на вкладку <strong>Посещение консультаций.</strong>
            </li>
            <li>Нажмите на кнопку <strong>[Добавить дату]</strong>.</li>
            <li>
              Заполните поля и нажмите на кнопку <strong>[Сохранить].</strong>
            </li>
          </ol>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
