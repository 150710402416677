<mat-spinner
  mode="indeterminate"
  *ngIf="!this.isLoad"
  class="spinner"
  color="primary"
></mat-spinner>
<div *ngIf="this.profileData">
  <div class="header-row">
    <h2 class="header">
      {{
        'text.personalAccount.personalAccount'
          | translate: 'Личный кабинет пользователя'
      }}
    </h2>
  </div>

  <div class="profile">
    <mat-card class="profile-card">
      <mat-card class="avatar">
        <img
          class="avatarImg"
          *ngIf="this.profileData.Avatar"
          src="{{ this.profileData.Avatar }}"
        />
        <span
          *ngIf="!this.profileData.Avatar"
          class="avatar-title rounded-circle bg-soft-primary text-primary"
        >
          {{
            this.profileData.Surname.charAt(0) + this.profileData.Name.charAt(0)
          }}
        </span>
      </mat-card>
      <input
        style="display: none"
        #fileInput
        type="file"
        (change)="onFileSelected($event)"
      />

      <div class="profile-buttons-row">
        <button
          mat-button
          (click)="setDefaultAvatar()"
          class="icon-close"
          [matTooltip]="
            'text.personalAccount.deletePhoto' | translate: 'Удалить фото'
          "
        >
          <mat-icon aria-hidden="false">close</mat-icon>
        </button>
        <button mat-button class="extra-button" (click)="fileInput.click()">
          {{ 'text.personalAccount.loadPhoto' | translate: 'Загрузить фото' }}
        </button>
      </div>
    </mat-card>

    <mat-card class="data-container">
      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.lastName' | translate: 'Фамилия'
        }}</mat-label>
        <input
          matInput
          id="surnameInput"
          [(ngModel)]="this.profileData.Surname"
          (blur)="this.trimFields()"
          [formControl]="this.dataGroup.controls.surnameFormControl"
          maxlength="30"
        />
        <mat-error *ngIf="this.dataGroup.controls.surnameFormControl.invalid">{{
          'text.personalAccount.wrongName'
            | translate: 'Допустимая длина поля от 1 до 30 символов'
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.firstName' | translate: 'Имя'
        }}</mat-label>
        <input
          matInput
          id="nameInput"
          [(ngModel)]="this.profileData.Name"
          (blur)="this.trimFields()"
          [formControl]="this.dataGroup.controls.nameFormControl"
          maxlength="30"
        />
        <mat-error *ngIf="this.dataGroup.controls.nameFormControl.invalid">{{
          'text.personalAccount.wrongName'
            | translate: 'Допустимая длина поля от 1 до 30 символов'
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.middleName' | translate: 'Отчество'
        }}</mat-label>
        <input
          matInput
          id="patronymicInput"
          [(ngModel)]="this.profileData.Patronymic"
          (blur)="this.trimFields()"
          [formControl]="this.dataGroup.controls.patronymicFormControl"
          maxlength="30"
        />
        <mat-error
          *ngIf="this.dataGroup.controls.patronymicFormControl.invalid"
          >{{
            'text.personalAccount.wrongName'
              | translate: 'Допустимая длина поля от 1 до 30 символов'
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.login' | translate: 'Логин'
        }}</mat-label>
        <input matInput disabled value="{{ this.profileData.UserName }}" />
      </mat-form-field>

      <mat-form-field
        class="input-full-width"
        *ngIf="this.profileData.GroupName"
      >
        <mat-label>{{
          'text.personalAccount.GroupName' | translate: 'Группа'
        }}</mat-label>
        <input matInput disabled value="{{ this.profileData.GroupName }}" />
      </mat-form-field>

      <a
        [matTooltip]="
          'text.personalAccount.followToChangePassword'
            | translate: 'Перейдите, чтобы сменить пароль'
        "
        (click)="this.openDialog()"
        >{{
          'text.personalAccount.changePassword' | translate: 'Изменить пароль'
        }}</a
      >
    </mat-card>

    <mat-card class="data-container">
      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.email' | translate: 'Почта'
        }}</mat-label>
        <input
          matInput
          id="emailInput"
          [(ngModel)]="this.profileData.Email"
          (blur)="this.trimFields()"
          [formControl]="this.dataGroup.controls.emailFormControl"
          maxlength="50"
        />
        <mat-error *ngIf="this.dataGroup.controls.emailFormControl.invalid">{{
          'text.personalAccount.wrongEmail'
            | translate: 'Почта не соответствует формату!'
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="input-full-width">
        <mat-label>{{
          'text.personalAccount.phoneNumber' | translate: 'Телефон'
        }}</mat-label>
        <input
          matInput
          id="phoneInput"
          [(ngModel)]="this.profileData.Phone"
          (blur)="this.trimFields()"
          [formControl]="this.dataGroup.controls.phoneFormControl"
          maxlength="20"
        />
        <mat-error
          *ngIf="this.dataGroup.controls.phoneFormControl.hasError('pattern')"
          >{{
            'text.personalAccount.wrongNumber'
              | translate: 'Номер не соответствует формату!'
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="text-area-full-width">
        <mat-label>{{
          'text.personalAccount.messenger'
            | translate: 'Skype, Viber, Telegram и др.'
        }}</mat-label>
        <textarea
          matInput
          id="skypeInput"
          [formControl]="this.dataGroup.controls.messangerFormControl"
          [(ngModel)]="this.profileData.SkypeContact"
          (blur)="this.deleteSpaces()"
          rows="2"
          maxlength="255"
        ></textarea>
      </mat-form-field>

      <mat-form-field class="text-area-full-width">
        <mat-label>{{
          'text.personalAccount.about' | translate: 'О себе'
        }}</mat-label>
        <textarea
          matInput
          id="aboutInput"
          [formControl]="this.dataGroup.controls.aboutFormControl"
          [(ngModel)]="this.profileData.About"
          (blur)="this.deleteSpaces()"
          rows="2"
          maxlength="1024"
        ></textarea>
      </mat-form-field>
    </mat-card>
  </div>

  <div class="buttons-row">
    <button mat-button class="cancel-button" (click)="this.backClicked()">
      {{ 'button.cancel' | translate: 'Отмена' }}
    </button>
    <button
      mat-raised-button
      class="edit-button"
      [disabled]="!this.dataGroup.dirty"
      (click)="this.updatePersonalInfo()"
    >
      {{ 'text.personalAccount.save' | translate: 'Сохранить' }}
    </button>
  </div>
</div>
