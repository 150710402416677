<div class="stats-table">
  <table mat-table id="excel-table" [dataSource]="this.data">
    <ng-container matColumnDef="position">
      <th id="position" mat-header-cell *matHeaderCellDef [hidden]="true">
        No.
      </th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>

    <ng-container matColumnDef="FIO">
      <th id="surname" mat-header-cell *matHeaderCellDef [hidden]="true">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.FIO }}</td>
    </ng-container>

    <ng-container matColumnDef="UserLecturePass">
      <th mat-header-cell class="column" *matHeaderCellDef>Лекции</th>
      <td mat-cell *matCellDef="let element">{{ element.UserLecturePass }}</td>
    </ng-container>

    <ng-container matColumnDef="UserLabPass">
      <th mat-header-cell class="column" *matHeaderCellDef>
        Лабораторные работы
      </th>
      <td mat-cell *matCellDef="let element">{{ element.UserLabPass }}</td>
    </ng-container>

    <ng-container matColumnDef="AllPass">
      <th mat-header-cell class="column" *matHeaderCellDef>Всего</th>
      <td class="all" mat-cell *matCellDef="let element">
        {{ element.AllPass }}
      </td>
    </ng-container>

    <ng-container matColumnDef="UserAvgLabMarks">
      <th mat-header-cell class="column" *matHeaderCellDef>Лабораторные</th>
      <td mat-cell class="user-lab-mark" *matCellDef="let element">
        {{ element.UserAvgLabMarks }}
      </td>
    </ng-container>

    <ng-container matColumnDef="UserAvgTestMarks">
      <th mat-header-cell class="column" *matHeaderCellDef>Тесты</th>
      <td mat-cell *matCellDef="let element">{{ element.UserAvgTestMarks }}</td>
    </ng-container>

    <ng-container matColumnDef="Rating">
      <th mat-header-cell class="column" *matHeaderCellDef>Рейтинг</th>
      <td mat-cell *matCellDef="let element">{{ element.Rating }}</td>
    </ng-container>

    <!-- Headers -->
    <ng-container matColumnDef="main-header">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="8">
        <div class="main-header">
          <p>{{ this.getGroupNameStr() }}</p>
          <p>{{ this.getSubjectNameStr() }}</p>
        </div>
      </th>
    </ng-container>

    <ng-container matColumnDef="family-header">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.colspan]="1"
        [attr.rowspan]="2"
      >
        #
      </th>
    </ng-container>

    <ng-container matColumnDef="option-header">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.colspan]="1"
        [attr.rowspan]="2"
      >
        Фамилия
      </th>
    </ng-container>

    <ng-container matColumnDef="omissions-header">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Пропуски, ч</th>
    </ng-container>

    <ng-container matColumnDef="average-mark-header">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Успеваемость</th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['main-header']"></tr>
    <tr mat-header-row *matHeaderRowDef="this.headerRow"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
