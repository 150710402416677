<mat-spinner
  mode="indeterminate"
  *ngIf="!this.isLoad"
  class="spinner"
  color="primary"
></mat-spinner>
<div class="main" *ngIf="isLoad">
  <h1>{{ 'text.group.statistics' | translate: 'Статистика успеваемости' }}</h1>
  <mat-form-field>
    <mat-label>{{
      'text.group.selectSubject' | translate: 'Выберите предмет'
    }}</mat-label>
    <mat-select
      [(ngModel)]="selectedItem"
      name="item"
      (ngModelChange)="this.statisticSubject()"
    >
      <mat-option *ngFor="let item of this.subjects" [value]="item.Id">{{
        item.Name
      }}</mat-option>
      <mat-option [value]="-1">{{
        'text.group.totalStatistics'
          | translate: 'Суммарная статистика по всем предметам'
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="export-button">
  <button class="btn" (click)="this.captureScreen()">
    {{ 'text.group.print' | translate: 'Печать' }}
  </button>
  <button class="btn" (click)="this.exportExcel()">
    <i class="fa fa-download"></i>Excel
  </button>
</div>
<div *ngIf="this.selectedItem">
  <app-table-for-stats-subject
    [data]="this.tableStats"
  ></app-table-for-stats-subject>
</div>
