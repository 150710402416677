<div class="header-row">
  <h2 class="header">
    {{'text.personalAccount.changingPassword' | translate : ("Изменение
    пароля")}}
  </h2>
</div>

<div mat-dialog-content class="main-frame">
  <form [formGroup]="this.form">
    <mat-form-field>
      <mat-label *ngIf="!showBadPasswordError"
        >{{'text.personalAccount.changePassword.putOldPassword' | translate :
        ("Введите старый пароль")}}</mat-label
      >
      <mat-label class="error-label" *ngIf="showBadPasswordError">
        {{'text.personalAccount.changePassword.wrongPassword' | translate :
        ("Неверный пароль!")}}
      </mat-label>
      <input
        matInput
        [type]="hideOld ? 'password' : 'text'"
        [(ngModel)]="this.oldPassword"
        formControlName="passwordFormControl"
        maxlength="30"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideOld = !hideOld"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideOld"
      >
        <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label
        >{{'text.personalAccount.changePassword.putNewPassword' | translate :
        ("Введите новый пароль")}}</mat-label
      >
      <input
        matInput
        [type]="hideNew ? 'password' : 'text'"
        [(ngModel)]="this.newPassword"
        formControlName="newPasswordFormControl"
        maxlength="30"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideNew = !hideNew"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideNew"
      >
        <mat-icon>{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-hint
        *ngIf="this.isControlInvalid('newPasswordFormControl') && !hasError('newPasswordFormControl', 'minlength') && !hasError('newPasswordFormControl', 'required') && !hasError('newPasswordFormControl', 'pattern')"
        >Пароль должен содержать хотя бы 1 маленькую и большую букву и 1
        цифру</mat-hint
      >
      <mat-error *ngIf="hasError('newPasswordFormControl', 'required')"
        >Поле не может быть пустым</mat-error
      >
      <mat-error *ngIf="hasError('newPasswordFormControl', 'minlength')"
        >Вы ввели меньше 6 символов.</mat-error
      >
      <mat-error *ngIf="hasError('newPasswordFormControl', 'maxlength')"
        >Вы ввели больше 30 символов.</mat-error
      >
      <mat-error *ngIf="hasError('newPasswordFormControl', 'pattern')"
        >Допустимые символы: "A(a)-Z(z)", "0-9", "_"</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label
        *ngIf="arePasswordsSame() || this.isControlInvalid('newPasswordRepeatFormControl')"
        >{{'text.personalAccount.changePassword.repeatPassword' | translate :
        ("Повторите пароль")}}</mat-label
      >
      <mat-label
        class="error-label"
        *ngIf="!arePasswordsSame() && !this.isControlInvalid('newPasswordRepeatFormControl')"
      >
        {{'text.personalAccount.changePassword.passwordsNotMatch' | translate :
        ("Пароли не совпадают!")}}
      </mat-label>
      <input
        matInput
        [type]="hideNewRepeat ? 'password' : 'text'"
        [(ngModel)]="this.newPasswordRepeat"
        formControlName="newPasswordRepeatFormControl"
        maxlength="30"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideNewRepeat = !hideNewRepeat"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideNewRepeat"
      >
        <mat-icon>{{hideNewRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions class="buttons-row">
  <button mat-button class="button-usual" (click)="onNoClick()">
    {{'button.cancel' | translate : ("Отмена")}}
  </button>
  <button
    mat-raised-button
    class="main-button"
    (click)="onSaveChangesClick()"
    [disabled]="this.invalidForm()"
    cdkFocusInitial
  >
    {{'text.personalAccount.changePassword.change' | translate : ("Изменить")}}
  </button>
</div>

<button mat-icon-button (click)="onNoClick()" class="icon-close">
  <mat-icon aria-hidden="false">close</mat-icon>
</button>
