import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-diplom',
  templateUrl: './diplom.component.html',
  styleUrls: ['./diplom.component.less'],
})
export class DiplomComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
